// import BigNumber from "bignumber.js";
// id: '池子id',
// name: '池子名字',  
// token: '池子代币',
// tokenDescription: '代币描述',
// tokenAddress: '代币ERC20地址',
// tokenDecimals: '存入精度'
// itokenDecimals: '提取精度'
// depostLimit: '存入最大数量限制' 0时不限制
// earnedToken: '奖励代币',
// earnedTokenAddress: '奖励代币ERC20地址',
// earnContractAddress: '池子合约地址',
// price ： 挖的代币的价格！
// path price: 
// -todo....

// export const isBSC = Number(window.ethereum.networkVersion) === 56
export const isBSC = window.localStorage.getItem("NETWORK_ID_USE") && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 56
// 线上
export const earnContractAddress = isBSC ? "0xb667809513BdEB16FcA9A0F8Cc147F82550fe604" : "0x560E6C29E70477271587b2Ca032AD077F1727849";
export const USDTAddress = isBSC ? '0x55d398326f99059fF775485246999027B3197955' : '0xa71EdC38d189767582C38A3145b5873052c3e47a';
export let GOVAddress = "";
export const setGOVAddress = (address) => GOVAddress = address;
// setTimeout(()=>{
//   const contract = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
//   const governanceAddress = await contract.methods.governance().call().catch(() => {
//       console.log('governance error')
//       return "";
//   })
// },3000)
// 测试的合约地址
// export const earnContractAddress =  isBSC ? "0x6bA7d75eC6576F88a10bE832C56F0F27DC040dDD" : "0x560E6C29E70477271587b2Ca032AD077F1727849";
// 测试策略地址
// export const earnContractAddress = "0xfFbCA0299B6540b400fe6343ca86df85b6184Ac1"
// 新增代币地址
// export const earnContractAddress = "0x0298c2b32eae4da002a15f36fdf7615bea3da047"
export const pools = isBSC ? [
  {
    // 标识新的，表示开放额度
    type: '',
    // 开放额度
    openAmount: "2,400,000",
    // 开放时间
    openDate: "2021.04.12(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX',
    name: 'MDX',
    token: 'MDX',
    tokenDescription: 'MDX',
    tokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    earnContractAddress,
    defaultApy: "405.30",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 2400000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    type: '',
    // 开放额度
    openAmount: "20,000,000",
    // 开放时间
    openDate: "2021.04.12(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'BUSD',
    name: 'BUSD',
    token: 'BUSD',
    tokenDescription: 'BUSD',
    tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    earnContractAddress,
    defaultApy: "63.00",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 391,
    total: 20000000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    type: '',
    // 开放额度
    openAmount: "63,000",
    // 开放时间
    openDate: "2021.04.12(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WBNB',
    name: 'WBNB',
    token: 'WBNB',
    tokenDescription: 'WBNB',
    tokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    earnContractAddress,
    defaultApy: "130.10",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 63000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    type: '',
    // 开放额度
    openAmount: "82,000,000",
    // 开放时间
    openDate: "2021.04.12",
    // 开放了吗
    disabled: false,
    id: 'USDT',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'USDT',
    tokenAddress: USDTAddress,
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    earnContractAddress,
    defaultApy: "64.30",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 0.169315,
    total: 82000000,
  },{
    // 标识新的，表示开放额度
    type: '',
    // 开放额度
    openAmount: "400",
    // 开放时间
    openDate: "2021.04.12(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'BTCB',
    name: 'BTCB',
    token: 'BTCB',
    tokenDescription: 'BTCB',
    tokenAddress: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    earnContractAddress,
    defaultApy: "48.04",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 391,
    total: 400
  },
  {
    // 标识新的，表示开放额度
    type: '',
    // 开放额度
    openAmount: "19,000",
    // 开放时间
    openDate: "2021.04.12(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'ETH',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'ETH',
    tokenAddress: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    earnContractAddress,
    defaultApy: "60.04",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 391,
    total: 19000
  },
] : [
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.03.17(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX',
    name: 'MDX',
    token: 'MDX',
    tokenDescription: 'MDX',
    tokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: '三期',
    // 开放额度
    openAmount: "14,000,000",
    // 开放时间
    openDate: "2021.03.19 14:00(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'USDT',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'USDT',
    tokenAddress: USDTAddress,
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "58.06",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 0.169315,
    total: 500000000,
    // 是否上首页
    topHome: true
  }, {
    // 标识新的，表示开放额度
    // type: '三期',
    // 开放额度
    openAmount: "14,000,000",
    // 开放时间
    openDate: "2021.03.19 14:00(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HUSD',
    name: 'HUSD',
    token: 'HUSD',
    tokenDescription: 'HUSD',
    tokenAddress: "0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047",
    tokenDecimals: 8,
    itokenDecimals: 8,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "58.06",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 0.169315,
    total: 500000000,
    // 是否上首页
    topHome: true
  },
]