import React,{useState} from 'react';
import '../jss/sections/SectionQuestion.css'
export default props => {
    const {value,label,type=0} = props;
    const [show,setShow] = useState(false)
    function toggleShow(e){
        e.stopPropagation()
        setShow(!show)
    }
    return <div className="g-pop-tips"  label={label} onClick={e=>e.stopPropagation()} onMouseEnter={toggleShow} onMouseLeave={toggleShow}>
        {show?<div className="g-pop-tips__inner">{value}</div>:null}
        
    </div>
}