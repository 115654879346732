/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
// nodejs library to set properties for components
import { useHistory } from 'react-router-dom';
import { renderIcon } from '@download/blockies'
import { useTranslation } from 'react-i18next';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";
import DropDown from "../DropDown/DropDown.js"
import './HeaderLinks.css'


// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/components/headerLinksStyle.js";
import { primaryColor } from "assets/jss/material-kit-pro-react";
const useStyles = makeStyles(styles);
export default function HeaderLinks(props) {
  const history = useHistory();
  const { dropdownHoverColor, connected, address, connectWallet, disconnectWallet } = props;
  const [lng, setLanguage] = useState('zh');
  const [opened, setOpened] = useState(false);
  const { t, i18n } = useTranslation();
  const [shortAddress, setShortAddress] = useState('');
  const [dataUrl, setDataUrl] = useState(null)
  const [slide, setSlide] = useState(0);
  // const canvasRef = useRef(null)
  // style
  const classes = useStyles();
  const [net, setNet] = useState();

  // 新增结束
  useEffect(() => {
    if (!connected) return;
    if (address.length < 11) {
      setShortAddress(address)
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`)
    }
  }, [dataUrl, address])

  const footerLinkArrHecoZh = [
    { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_zh.pdf'},
    { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_zh.pdf'},
    { content: 'Tutorial-1', href: 'https://www.yuque.com/coinwind/tutorial_cn/walletcreate' },
    { content: 'Tutorial-2', href: 'https://www.yuque.com/coinwind/tutorial_cn/singlefarm' },
    { content: 'Tutorial-3', href: 'https://www.yuque.com/coinwind/tutorial_cn/lpsinglefarm' },
    { content: 'Tutorial-4', href: 'https://www.yuque.com/coinwind/tutorial_cn/contactus' },
];
const footerLinkArrHecoEn = [
    { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_en.pdf'},
    { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_en.pdf'},
    { content: 'Tutorial-1', href: 'https://www.yuque.com/coinwind/tutorial_en/walletcreate' },
    { content: 'Tutorial-2', href: 'https://www.yuque.com/coinwind/tutorial_en/singlefarm' },
    { content: 'Tutorial-3', href: 'https://www.yuque.com/coinwind/tutorial_en/lpsinglefarm' },
    { content: 'Tutorial-4', href: 'https://www.yuque.com/coinwind/tutorial_en/contactus' },
];
const footerLinkArrBscZh = [
    { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_zh.pdf'},
    { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_zh.pdf'},
    { content: 'Tutorial-1', href: 'https://www.yuque.com/coinwind/tutorial_cn/walletcreate' },
    { content: 'Tutorial-2', href: 'https://www.yuque.com/coinwind/tutorial_cn/singlefarm' },
    { content: 'Tutorial-3', href: 'https://www.yuque.com/coinwind/tutorial_cn/lpsinglefarm' },
    { content: 'Tutorial-4', href: 'https://www.yuque.com/coinwind/tutorial_cn/contactus' },
];
const footerLinkArrBscEn = [
    { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_en.pdf'},
    { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_en.pdf'},
    { content: 'Tutorial-1', href: 'https://www.yuque.com/coinwind/tutorial_en/walletcreate' },
    { content: 'Tutorial-2', href: 'https://www.yuque.com/coinwind/tutorial_en/singlefarm' },
    { content: 'Tutorial-3', href: 'https://www.yuque.com/coinwind/tutorial_en/lpsinglefarm' },
    { content: 'Tutorial-4', href: 'https://www.yuque.com/coinwind/tutorial_en/contactus' },
];

  let tutorilList;
  if ((i18n.language === 'zh' || i18n.language === 'zh_CN') && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 128) {
    tutorilList = footerLinkArrHecoZh;
  } else if ((i18n.language === 'en' || i18n.language === 'en_US') && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 128) {
    tutorilList = footerLinkArrHecoEn;
  } else if ((i18n.language === 'zh' || i18n.language === 'zh_CN') && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 56) {
    tutorilList = footerLinkArrBscZh;
  } else {
    tutorilList = footerLinkArrBscEn;
  }

  const onConnectClick = event => {
    setNet(event);
    switch (event) {
      case "BSC":
        ethereum.request({
          method: "wallet_addEthereumChain",
          params: [{
            chainId: "0x38",
            chainName: "BSC Mainnet",
            nativeCurrency: {
              name: "BSC",
              symbol: "BNB",
              decimals: 18
            },
            rpcUrls: ["https://bsc.mytokenpocket.vip"],
            blockExplorerUrls: ["https://bscscan.com/"]
          }]
        }).then((function (e) {
          window.localStorage.setItem("NETWORK_ID_USE", 56)
        }
        ));
        break;
      case "HECO":
        ethereum.request({
          method: "wallet_addEthereumChain",
          params: [{
            chainId: "0x80",
            chainName: "Heco Mainnet",
            nativeCurrency: {
              name: "Heco",
              symbol: "HT",
              decimals: 18
            },
            rpcUrls: ["https://http-mainnet-node.defibox.com"],
            blockExplorerUrls: ["https://hecoinfo.com"]
          }]
        }).then((function (e) {
          window.localStorage.setItem("NETWORK_ID_USE", 128)
        }
        ));
        break;
      default:
        alert("Please change the metamask to Ethereum");
        break
    }
  }

  const switchNet = () => {
    switch (Number(window.localStorage.getItem("NETWORK_ID_USE"))) {
      case 56:
        return "BSC";
      case 128:
        return 'HECO';
      default:
        return "HECO";
    }
  }

  useEffect(() => {
    const net = switchNet()
    setNet(net);
  });

  // 切换语言
  const dropDownList = [
    {
      text: "中文",
      id: "1",
    },
    {
      text: "English",
      id: "2",
    },
  ]
  function getLanguage(props) {
    switch (props) {
      case 'English':
        return i18n.changeLanguage('en').then(() => setLanguage(props))
      case '中文':
        return i18n.changeLanguage('zh').then(() => setLanguage(props))
      default:
        return
    }
  }
  const switchLanguage = () => {
    switch (i18n.language) {
      case 'zh':
      case 'zh-CN':
        return '中文'
      case 'en':
        return 'English'
    }
  }
  useEffect(() => {
    const lng = switchLanguage()
    setLanguage(lng);
  });
  // 切换语言结束

  let defaultTabValue = '';
  if (window.location.hash != '#/' && window.location.hash != '#/index') {
    defaultTabValue = window.location.hash.split('/')[1];
  }

  return (
    <>
      <Hidden smDown implementation="css">
        <div className="header__button-container" style={{ display: "flex" }}>
          <div className={classes.networkDrop}>
            <CustomDropdown
              navDropdown
              hoverColor={dropdownHoverColor}
              buttonText={net}
              popperClassName={classes.popperClass}
              buttonProps={{
                className: classes.navLink,
                // color: "transparent",
              }}
              onClick={onConnectClick}
              dropdownList={[
                "HECO",
                "BSC",
              ]}
            />
          </div>
          <Button
            style={
              {
                marginLeft: '32px',
              }
            }
            className={classes.navLink}
            round
            type="button"
            color="primary"
            onClick={connected ? disconnectWallet : connectWallet}
          >
            {connected ?
              (
                <>
                  {shortAddress}
                </>
              ) :
              (
                <>
                  {t('Vault-Wallet')}
                </>
              )}
          </Button>
        </div>
      </Hidden>
      <Hidden mdUp implementation="css">
        <Button
          className={classes.navLink}
          round
          type="button"
          color="primary"
          onClick={connected ? disconnectWallet : connectWallet}
        >
          {connected ?
            (
              <>
                {shortAddress}
              </>
            ) :
            (
              <>
                {t('Vault-Wallet')}
              </>
            )}
        </Button>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            setSlide(1)
          }}
        >
          <Menu />
        </IconButton>
        <div className={`slider__mask ${slide ? 'slide__show' : ''}`} onClick={() => {
          setSlide(0)
        }}>
          <div className={`slider__content ${slide ? 'slide__content-show' : ''}`}>
            {/* 语言切换 */}
            <div className="nav__language-container" onClick={(e) => {
              e.stopPropagation();
              setOpened(!opened);
            }}>
              <i className="language__icon"></i>
              <DropDown
                className="nav__language"
                buttonText={lng}
                opened={opened}
                dropDownList={dropDownList}
                position="bottom"
                buttonClickCallback={(props) => {
                  getLanguage(props);
                  setOpened(!opened);
                  setSlide(0);
                }}
              >
              </DropDown>
              <strong style={{
                color: primaryColor[2],
                width: "0",
                height: "0",
                display: "inline-block",
                borderTop: "4px solid",
                transition: "all 150ms ease-in",
                borderLeft: "4px solid transparent",
                marginLeft: "4px",
                borderRight: "4px solid transparent",
                verticalAlign: "middle",
                position: "absolute",
                right: "32px",
                top: "30px",
                transform: opened ? "rotate(180deg)" : ''
              }}></strong>
            </div>
            {Number(window.localStorage.getItem("NETWORK_ID_USE")) === 128 ? <div className='nav__language-container' style={{
              marginTop: "0",
            }} onClick={() => {
                window.open('https://v1.coinwind.com/')
            }}>
                <i className="language__icon"></i>
                <span style={{
                  // fontFamily: "MicrosoftYaHei-Bold",
                  fontSize: "14px",
                  color: "#8391A8",
                  fontWeight: "700",
                }}>{t('v2-ui-v1')}</span>
            </div> : ''}
            <div className="tutoril__container">
              {
                tutorilList.map((item, ind) => {
                  return (
                    <a className="tutoril__item" href={item.href} key={ind} target="_blank">
                      <span>{t(item.content)}</span>
                      <img src={require('../../images/icon-more.svg')} alt="" />
                    </a>
                  )
                })
              }
            </div>
          </div>
        </div>
      </Hidden>
    </>
  );
}

