import React from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '../../../components/Switch/Switch'
import '../jss/sections/SectionFilter.css'
export default ({filterPartakeHandler,isPartake}) => {
    const { t } = useTranslation();
    return <div className="g-filter">
        <Switch onChange={filterPartakeHandler} isPartake={isPartake}>{t('v2-ui-21')}</Switch>
    </div>

}