import React, { useEffect, useState, memo } from 'react';
import { earnContractAddress as EARN_CONTRACT_ADDRESS } from "../../configure/pools";
import '../jss/sections/ControlManyManager.css'
// import { useSnackbar } from 'notistack';
// import Button from "components/CustomButtons/Button.js";
// import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { useConnectWallet } from '../../home/redux/hooks';
import { useFetchPoolBalances, usePoolManager, useGov } from '../redux/hooks';
export default memo(function () {
  const { web3, address } = useConnectWallet();
  const { pools } = useFetchPoolBalances();
  const { adminWithdraw, strategyPools, nameArr, getStrategy } = usePoolManager()
  // console.log(nameArr, 'nameArr nameArr ')
  strategyPools.forEach((item, index) => {
    item.name = nameArr[index]
  })
  const { govWithdrawLp, govEarnLp } = useGov()
  const [selectedAddress, setSelectedAddress] = useState(pools[0].tokenAddress);
  const targetPools = [
    {
      token: 'hubPool',
      tokenAddress: EARN_CONTRACT_ADDRESS,
    },
    {
      token: '老的 controller',
      tokenAddress: "0x31248bF7857E56705C155772FC46119b3C4f16A0",
    },
    {
      token: '新的 controller',
      tokenAddress: "0x366dF9F06a685cD2993D46718ee9d31d8268E52e",
    }
  ]
  const [targetAddress, setTargetAddress] = useState(targetPools[0].tokenAddress);
  // 币对
  const [selectedPolicyAddress, setSelectedPolicyAddress] = useState('');
  const [selectedPolicyAddress1, setSelectedPolicyAddress1] = useState('');
  // 币对对应的币种
  const [matchedPolicyCurrency, setMatchedPolicyCurrency] = useState([]);
  const [matchedPolicyCurrency1, setMatchedPolicyCurrency1] = useState([]);
  // 选中的币种
  const [selectedMatchedPolicyCurrency, setselectedMatchedPolicyCurrency] = useState('');
  const [withdraw, setWithdraw] = useState('')
  // 调仓金额
  const [tiaocangAmount, setTiaocangAmount] = useState('');
  const [tiaocangAmountBefore, setTiaocangAmountBefore] = useState('');
  const [tiaocangAmountAfter, setTiaocangAmountAfter] = useState('');


  //

  useEffect(() => {
    if (!web3 || !address) return
    getStrategy()
    const getStrategyTimer = window.setInterval(() => {
      getStrategy()
    }, 5000)
    return () => {
      window.clearInterval(getStrategyTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, address])
  useEffect(() => {
    if (!selectedPolicyAddress) return;
    try {
      const want = strategyPools.find(pool => pool.address === selectedPolicyAddress).want
      setMatchedPolicyCurrency([{ address: want['0'], token: want['00'] }, { address: want['1'], token: want['11'] }])
    } catch (e) { }
  }, [selectedPolicyAddress, strategyPools])
  useEffect(() => {
    if (!selectedPolicyAddress1) return;
    try {
      const want1 = strategyPools.find(pool => pool.address === selectedPolicyAddress1).want
      // console.log('setMatchedPolicyCurrency1', want1)
      setMatchedPolicyCurrency1([{ address: want1['0'], token: want1['00'] }, { address: want1['1'], token: want1['11'] }])
    } catch (e) { }
  }, [selectedPolicyAddress1, strategyPools])

  return <>
    <h4 className="ControlManyManager-h4">提取</h4>
    <div className="ControlManyManager">
      <select value={selectedAddress} onChange={(e) => setSelectedAddress(e.target.value)}>
        {
          pools.map(pool => {
            return <option value={pool.tokenAddress}>{pool.token}</option>
          })
        }
      </select>
      <select value={targetAddress} onChange={(e) => setTargetAddress(e.target.value)}>
        {
          targetPools.map(pool => {
            return <option value={pool.tokenAddress}>{pool.token}</option>
          })
        }
      </select>
      <input value={withdraw} onChange={e => setWithdraw(e.target.value)} />
      <button onClick={() => adminWithdraw(targetAddress, selectedAddress, withdraw, pools.find(pool => pool.tokenAddress === selectedAddress).tokenDecimals)}>提取</button>
    </div>
    <h4 className="ControlManyManager-h4">调仓解押</h4>
    <div className="ControlManyManager">
      <select value={selectedPolicyAddress} onChange={(e) => setSelectedPolicyAddress(e.target.value)}>
        <option value={0}>请选择</option>
        {
          strategyPools.map(pool => {
            console.log(pool.name, 'pool.name pool.name ')
            return <option value={pool.address}>
              「{pool.name}」{pool.want['00']}/{pool.want['11']} 
            </option>
          })
        }
      </select>
      <select value={selectedMatchedPolicyCurrency} onChange={(e) => setselectedMatchedPolicyCurrency(e.target.value)}>
        <option value={0}>请选择</option>
        {
          matchedPolicyCurrency.map(Policy => {
            return <option value={Policy.address}>{Policy.token}</option>
          })
        }

      </select>
      <input value={tiaocangAmount} onChange={e => setTiaocangAmount(e.target.value)} />
      <button onClick={govWithdrawLp.bind(this, strategyPools.findIndex(pool => pool.address === selectedPolicyAddress), selectedMatchedPolicyCurrency, tiaocangAmount)}>确定</button>
    </div>
    <h4 className="ControlManyManager-h4">调仓质押</h4>
    <div className="ControlManyManager">
      <select value={selectedPolicyAddress1} onChange={(e) => setSelectedPolicyAddress1(e.target.value)}>
        <option value={0}>请选择</option>
        {
          strategyPools.map(pool => {
            return <option value={pool.address}>
              「{pool.name}」{pool.want['00']}/{pool.want['11']} 
            </option>
          })
        }
      </select>
      {/* <select value={selectedMatchedPolicyCurrency} onChange={(e) => setselectedMatchedPolicyCurrency(e.target.value)}>
        <option value={0}>请选择</option>
        {
          matchedPolicyCurrency.map(Policy => {
            return <option value={Policy.address}>{Policy.token}</option>
          })
        }

      </select> */}
      
      <span className="ControlManyManager-label">{matchedPolicyCurrency1[0]?matchedPolicyCurrency1[0].token:'xx'}:</span>
      <input value={tiaocangAmountBefore} onChange={e => setTiaocangAmountBefore(e.target.value)} />
      <span className="ControlManyManager-label">{matchedPolicyCurrency1[1]?matchedPolicyCurrency1[1].token:'xx'}:</span>
      <input value={tiaocangAmountAfter} onChange={e => setTiaocangAmountAfter(e.target.value)} />
      <button onClick={govEarnLp.bind(this, strategyPools.findIndex(pool => pool.address === selectedPolicyAddress1), tiaocangAmountBefore, tiaocangAmountAfter)}>确定</button>
    </div>
  </>

});