import React, { useEffect, useState, useRef, useMemo, useCallback, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import { inputLimitPass, inputFinalVal, isEmpty } from 'features/helpers/utils';
import '../jss/sections/ControlPoolManager.css'
import { useFetchPoolBalances, usePoolManager } from '../redux/hooks';
export default memo(function () {
  const { enqueueSnackbar } = useSnackbar();
  let { pools, fetchPoolBalances } = useFetchPoolBalances();
  // 打开关闭新增内容区域
  const [openAdd, setOpenAdd] = useState(false);
  // 新增合信息
  const [contractInfo, setContractInfo] = useState({
    contractAddress: '',
    profit: ''
  })
  // 输入合约代币地址和分成比例
  const inputContractInfoHandler = (prop, event) => {
    const v = event.target.value;
    if (prop === 'profit' && (!inputLimitPass(v, 2) || isNaN(v))) {
      return
    }
    setContractInfo({
      ...contractInfo,
      [prop]: v
    })
  }

  // 确认合约信息弹出框
  const { checkContract, addNewPool, isPoolAdded,editPoolControl } = usePoolManager();
  const [checkedContractInfo, setCheckedContractInfo] = useState([])
  const [confirmOpened, setConfirmOpened] = useState(false);
  // 确认添加新合约

  // 1、点击验证各字段规则，

  // 2、从链上确认合约地址是否合法

  // 3、合约地址是否已添加

  // 全部通过，调起合约
  const submitNewContract = async () => {
    // 1、点击验证各字段规则
    if (!contractInfo.contractAddress ||contractInfo.contractAddress.length!==42|| Number(contractInfo.profit) > 100 || !Number(contractInfo.profit)) {
      enqueueSnackbar('请输入正确的代币地址和平台分成比例字段信息！', {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
      return;
    }
    // 2、如果已经在池子里面
    // const poolId = await isPoolAdded(contractInfo.contractAddress)
    if (await isPoolAdded(contractInfo.contractAddress)) {
      enqueueSnackbar('合约代币地址已添加,不能重复添加！', {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
      return;
    }
    // 查询合约名称
    const nameAnddecimals = await checkContract(contractInfo.contractAddress).catch(e => false)
    // console.log('nameAnddecimals',nameAnddecimals)
    if (!nameAnddecimals) {
      enqueueSnackbar('合约代币地址非法！', {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
      return;
    } else {
      // 查出来的合约名称
      setCheckedContractInfo(nameAnddecimals);
      // 弹出确认框
      setConfirmOpened(true)
    }
  }
  // 调起合约
  const callContract = () => {
    addNewPool({ ...contractInfo, decimals: checkedContractInfo[1] }).then(ret => {
      enqueueSnackbar(`增加成功:${ret}`, {
        variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000,
        action: ret ? () => <button onClick={() => window.open(`https://hecoinfo.com/tx/${ret}`, "_blank")}><span style={{ color: "white" }}>查看</span></button> : null
      });
      setConfirmOpened(false);
    }).catch(e => {
      enqueueSnackbar(`增加新合约失败:${JSON.stringify(e)}`, {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
    });
  }
  // 打开编辑策略
  const editTokens = {};
  pools.forEach(pool => {
    editTokens[pool.token] = {
      isOpened: false,
      editInfo: ''
    }
  });
  // const [editTokensByPool, setEditTokensByPool] = useState(editTokens);
  // const openEditHandler = (token) => {
  //   Object.keys(editTokensByPool).forEach(key => {
  //     if (key !== token) {
  //       editTokensByPool[key].isOpened = false
  //     }
  //   })
  //   editTokensByPool[token].isOpened = !editTokensByPool[token].isOpened
  //   setEditTokensByPool({
  //     ...editTokensByPool,
  //   })
  // }
  // 输出策略编辑
  // const changeEditTokensByPoolHandler = (token,event)=>{
  //   const val = event.target.value;
  //   setEditTokensByPool({
  //     ...editTokensByPool,
  //     [token]:{
  //       ...editTokensByPool[token],
  //       editInfo:val
  //     }
  //   })
  // }
  // 提交编辑的策略地址
  // const submitEditControlAddress = (pool) => {
  //   const controlAddress = editTokensByPool[pool.token].editInfo;
  //   // 1、点击验证各字段规则
  //   if (!controlAddress ||controlAddress.length!==42) {
  //     enqueueSnackbar('请输入正确的Control策略地址！', {
  //       variant: 'error', anchorOrigin: {
  //         vertical: 'top',
  //         horizontal: 'center'
  //       },
  //       autoHideDuration: 4000
  //     })
  //     return;
  //   }
  //   // console.log('submitEditControlAddress:',editTokensByPool[pool.token].editInfo)
  //   editPoolControl(controlAddress).then((ret)=>{
  //     enqueueSnackbar(`Control策略地址:${ret}`, {
  //       variant: 'success', anchorOrigin: {
  //         vertical: 'top',
  //         horizontal: 'center'
  //       },
  //       autoHideDuration: 4000,
  //       action: ret ? () => <Button onClick={() => window.open(`https://hecoinfo.com/tx/${ret}`, "_blank")}><span style={{ color: "white" }}>查看</span></Button> : null
  //     });
  //   }).catch(e=>{
  //     enqueueSnackbar(`Control策略地址失败:${JSON.stringify(e)}`, {
  //       variant: 'error', anchorOrigin: {
  //         vertical: 'top',
  //         horizontal: 'center'
  //       },
  //       autoHideDuration: 4000
  //     })
  //   })
  //   // console.log('todo....',pool,editTokensByPool[pool.token])
  // }
  return <div className="ControlPool">
    <button
      className="ControlPool-button"
      onClick={() => setOpenAdd(!openAdd)}
    >
      新增
    </button>
    {/* 新增输入框 */}
    {
      openAdd ? <div className={"ControlPool-add" + (openAdd ? ' ControlPool-add--opened' : '')}>
        <h5>合约信息</h5>
        <label>合约代币地址:</label>
        <div>
          <input
            value={contractInfo.contractAddress}
            onChange={inputContractInfoHandler.bind(this, 'contractAddress')}
          />
        </div>
        <label>平台分成比例(%):</label>
        <div>
          <input
            value={contractInfo.profit}
            onChange={inputContractInfoHandler.bind(this, 'profit')}
          />
        </div>
        <button
          className="ControlPool-button ControlPool-button-list"
          onClick={submitNewContract.bind(this)}
        >
          确认
      </button>
      </div> : null
    }
    <Dialog
      open={confirmOpened}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>确认合约信息</DialogTitle>
      <DialogContent>
        <DialogContentText>
          合约名称:{checkedContractInfo[0]}
        </DialogContentText>
        <DialogContentText>
          合约地址:{contractInfo.contractAddress}
        </DialogContentText>
        <DialogContentText>
          币种精度:{checkedContractInfo[1]}
        </DialogContentText>
        <DialogContentText>
          平台分成比例:{contractInfo.profit}%
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button onClick={() => setConfirmOpened(false)} color="primary">
          取消
          </button>
        <button onClick={callContract.bind(this)} color="primary">
          确认新增
          </button>
      </DialogActions>
    </Dialog>
    <ul className="ControlPool-list">
      {
        pools.map((pool,index) => {
          return <li key={index}>
            <div>合约名称:</div>
            {pool.token}
            <div>合约地址:</div>
            {pool.tokenAddress}
            {/* <Button
              className="ControlPool-button ControlPool-button-list"
              color="primary"
              onClick={openEditHandler.bind(this, pool.token)}
            >
              编辑策略
            </Button> */}
            {/* {
              editTokensByPool[pool.token].isOpened ? <div className={"ControlPool-add ControlPool-edit" + (editTokensByPool[pool.token].isOpened ? ' ControlPool-add--opened' : '')}>
                <h5>策略信息</h5>
                <label>Control策略地址:</label>
                <div>
                  <CustomOutlinedInput
                    value={editTokensByPool[pool.token].editInfo}
                    onChange={changeEditTokensByPoolHandler.bind(this,pool.token)}
                  />
                </div>
                <Button
                  className="ControlPool-button ControlPool-button-list"
                  color="primary"
                  onClick={submitEditControlAddress.bind(this, pool)}
                >
                  确认
               </Button>
              </div> : null
            } */}
          </li>
        })
      }
    </ul>
  </div>

});