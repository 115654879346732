export const AggregateHub = [
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "previousOwner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "OwnershipTransferred",
	  "type": "event"
	},
	{
	  "inputs": [],
	  "name": "bscBusd",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "bscMdxRouter",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "bscUsdt",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "tokenA",
		  "type": "address"
		}
	  ],
	  "name": "decimals",
	  "outputs": [
		{
		  "internalType": "uint8",
		  "name": "",
		  "type": "uint8"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		}
	  ],
	  "name": "getController",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "ctr",
		  "type": "address"
		}
	  ],
	  "name": "getControllerAdmin",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "ctr",
		  "type": "address"
		}
	  ],
	  "name": "getControllerBalance",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "ctr",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "ssId",
		  "type": "uint256"
		}
	  ],
	  "name": "getCtrStrategyAddress",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "lpToken",
		  "type": "address"
		},
		{
		  "internalType": "uint8",
		  "name": "_type",
		  "type": "uint8"
		}
	  ],
	  "name": "getLPUsdtRate",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "lpToken",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "lpAmount",
		  "type": "uint256"
		},
		{
		  "internalType": "uint8",
		  "name": "_type",
		  "type": "uint8"
		}
	  ],
	  "name": "getLpTokenInfo",
	  "outputs": [
		{
		  "components": [
			{
			  "internalType": "uint256",
			  "name": "amountUsd",
			  "type": "uint256"
			},
			{
			  "internalType": "address",
			  "name": "tokenA",
			  "type": "address"
			},
			{
			  "internalType": "uint256",
			  "name": "amountA",
			  "type": "uint256"
			},
			{
			  "internalType": "address",
			  "name": "tokenB",
			  "type": "address"
			},
			{
			  "internalType": "uint256",
			  "name": "amountB",
			  "type": "uint256"
			}
		  ],
		  "internalType": "struct LpTokenInfo",
		  "name": "ret",
		  "type": "tuple"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "ss",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "tokenA",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "tokenB",
		  "type": "address"
		}
	  ],
	  "name": "getMdexLpSupply",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "ss",
		  "type": "address"
		},
		{
		  "internalType": "string",
		  "name": "name",
		  "type": "string"
		}
	  ],
	  "name": "getMdexTotalLp",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		}
	  ],
	  "name": "getPoolAdmin",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "ctr",
		  "type": "address"
		}
	  ],
	  "name": "getPoolList",
	  "outputs": [
		{
		  "components": [
			{
			  "components": [
				{
				  "internalType": "contract IERC20",
				  "name": "token",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "lastRewardBlock",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "accMdxPerShare",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "govAccMdxPerShare",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "accMdxShare",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "totalAmount",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "totalAmountLimit",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "profit",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "earnLowerlimit",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "min",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "lastRewardBlockProfit",
				  "type": "uint256"
				}
			  ],
			  "internalType": "struct PoolInfo",
			  "name": "poolInfo",
			  "type": "tuple"
			},
			{
			  "internalType": "uint256",
			  "name": "unUsedAmount",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "poolId",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "usedAmount",
			  "type": "uint256"
			}
		  ],
		  "internalType": "struct PoolInfoExt[]",
		  "name": "",
		  "type": "tuple[]"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "mdexRouter",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "tokenA",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "tokenB",
		  "type": "address"
		}
	  ],
	  "name": "getReserves",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		},
		{
		  "internalType": "uint8",
		  "name": "_type",
		  "type": "uint8"
		}
	  ],
	  "name": "getRewardTokenRate",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "ss",
		  "type": "address"
		}
	  ],
	  "name": "getStrategyInfo",
	  "outputs": [
		{
		  "components": [
			{
			  "internalType": "address",
			  "name": "ssAddress",
			  "type": "address"
			},
			{
			  "internalType": "uint256",
			  "name": "mdexLpAmount",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "lp",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "totalSupply",
			  "type": "uint256"
			},
			{
			  "internalType": "address",
			  "name": "tokenAAddress",
			  "type": "address"
			},
			{
			  "internalType": "address",
			  "name": "tokenBAddress",
			  "type": "address"
			},
			{
			  "internalType": "uint256",
			  "name": "amountA",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "amountB",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "poolAmountA",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "poolAmountB",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "reward",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "historyReward",
			  "type": "uint256"
			},
			{
			  "internalType": "address",
			  "name": "admin",
			  "type": "address"
			},
			{
			  "internalType": "string",
			  "name": "strategyName",
			  "type": "string"
			}
		  ],
		  "internalType": "struct StrategyInfo",
		  "name": "",
		  "type": "tuple"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "ctr",
		  "type": "address"
		}
	  ],
	  "name": "getStrategyList",
	  "outputs": [
		{
		  "components": [
			{
			  "internalType": "address",
			  "name": "ssAddress",
			  "type": "address"
			},
			{
			  "internalType": "uint256",
			  "name": "mdexLpAmount",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "lp",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "totalSupply",
			  "type": "uint256"
			},
			{
			  "internalType": "address",
			  "name": "tokenAAddress",
			  "type": "address"
			},
			{
			  "internalType": "address",
			  "name": "tokenBAddress",
			  "type": "address"
			},
			{
			  "internalType": "uint256",
			  "name": "amountA",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "amountB",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "poolAmountA",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "poolAmountB",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "reward",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "historyReward",
			  "type": "uint256"
			},
			{
			  "internalType": "address",
			  "name": "admin",
			  "type": "address"
			},
			{
			  "internalType": "string",
			  "name": "strategyName",
			  "type": "string"
			}
		  ],
		  "internalType": "struct StrategyInfo[]",
		  "name": "",
		  "type": "tuple[]"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "ss",
		  "type": "address"
		}
	  ],
	  "name": "getStrategyReward",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "token",
		  "type": "address"
		},
		{
		  "internalType": "uint8",
		  "name": "_type",
		  "type": "uint8"
		}
	  ],
	  "name": "getTokenUsdtRate",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "token",
		  "type": "address"
		},
		{
		  "internalType": "uint8",
		  "name": "_type",
		  "type": "uint8"
		},
		{
		  "internalType": "bool",
		  "name": "_islp",
		  "type": "bool"
		}
	  ],
	  "name": "getUsdtRate",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "hecoMdxRouter",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "hecoUsdt",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "owner",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "mdexRouter",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "tokenA",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "tokenB",
		  "type": "address"
		}
	  ],
	  "name": "rate",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "rateFloat",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "renounceOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_float",
		  "type": "uint256"
		}
	  ],
	  "name": "setRateFloat",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		}
	  ],
	  "name": "statLiqReward",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		}
	  ],
	  "name": "statPool",
	  "outputs": [
		{
		  "components": [
			{
			  "internalType": "address",
			  "name": "controller",
			  "type": "address"
			},
			{
			  "internalType": "address",
			  "name": "controllerAdmin",
			  "type": "address"
			},
			{
			  "internalType": "address",
			  "name": "poolAdmin",
			  "type": "address"
			},
			{
			  "internalType": "uint256",
			  "name": "controllerBalance",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "govTotalProfit",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "govTotalSendProfit",
			  "type": "uint256"
			},
			{
			  "components": [
				{
				  "components": [
					{
					  "internalType": "contract IERC20",
					  "name": "token",
					  "type": "address"
					},
					{
					  "internalType": "uint256",
					  "name": "lastRewardBlock",
					  "type": "uint256"
					},
					{
					  "internalType": "uint256",
					  "name": "accMdxPerShare",
					  "type": "uint256"
					},
					{
					  "internalType": "uint256",
					  "name": "govAccMdxPerShare",
					  "type": "uint256"
					},
					{
					  "internalType": "uint256",
					  "name": "accMdxShare",
					  "type": "uint256"
					},
					{
					  "internalType": "uint256",
					  "name": "totalAmount",
					  "type": "uint256"
					},
					{
					  "internalType": "uint256",
					  "name": "totalAmountLimit",
					  "type": "uint256"
					},
					{
					  "internalType": "uint256",
					  "name": "profit",
					  "type": "uint256"
					},
					{
					  "internalType": "uint256",
					  "name": "earnLowerlimit",
					  "type": "uint256"
					},
					{
					  "internalType": "uint256",
					  "name": "min",
					  "type": "uint256"
					},
					{
					  "internalType": "uint256",
					  "name": "lastRewardBlockProfit",
					  "type": "uint256"
					}
				  ],
				  "internalType": "struct PoolInfo",
				  "name": "poolInfo",
				  "type": "tuple"
				},
				{
				  "internalType": "uint256",
				  "name": "unUsedAmount",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "poolId",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "usedAmount",
				  "type": "uint256"
				}
			  ],
			  "internalType": "struct PoolInfoExt[]",
			  "name": "poolList",
			  "type": "tuple[]"
			},
			{
			  "components": [
				{
				  "internalType": "address",
				  "name": "ssAddress",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "mdexLpAmount",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "lp",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "totalSupply",
				  "type": "uint256"
				},
				{
				  "internalType": "address",
				  "name": "tokenAAddress",
				  "type": "address"
				},
				{
				  "internalType": "address",
				  "name": "tokenBAddress",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "amountA",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "amountB",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "poolAmountA",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "poolAmountB",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "reward",
				  "type": "uint256"
				},
				{
				  "internalType": "uint256",
				  "name": "historyReward",
				  "type": "uint256"
				},
				{
				  "internalType": "address",
				  "name": "admin",
				  "type": "address"
				},
				{
				  "internalType": "string",
				  "name": "strategyName",
				  "type": "string"
				}
			  ],
			  "internalType": "struct StrategyInfo[]",
			  "name": "ssList",
			  "type": "tuple[]"
			}
		  ],
		  "internalType": "struct Result",
		  "name": "",
		  "type": "tuple"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		},
		{
		  "internalType": "uint8",
		  "name": "_type",
		  "type": "uint8"
		},
		{
		  "internalType": "bool",
		  "name": "_islp",
		  "type": "bool"
		}
	  ],
	  "name": "statePoolAmountUsd",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "user",
		  "type": "address"
		},
		{
		  "internalType": "uint8",
		  "name": "_type",
		  "type": "uint8"
		},
		{
		  "internalType": "bool",
		  "name": "_islp",
		  "type": "bool"
		}
	  ],
	  "name": "statePoolAndUserTokenUsd",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		},
		{
		  "internalType": "uint8",
		  "name": "_type",
		  "type": "uint8"
		}
	  ],
	  "name": "statePoolRewardUsd",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		},
		{
		  "internalType": "uint8",
		  "name": "_type",
		  "type": "uint8"
		},
		{
		  "internalType": "bool",
		  "name": "_islp",
		  "type": "bool"
		}
	  ],
	  "name": "statePoolTokenUsd",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "hubPool",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "user",
		  "type": "address"
		},
		{
		  "internalType": "uint8",
		  "name": "_type",
		  "type": "uint8"
		},
		{
		  "internalType": "bool",
		  "name": "_islp",
		  "type": "bool"
		}
	  ],
	  "name": "stateUserAmountUsd",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "ss",
		  "type": "address"
		}
	  ],
	  "name": "strategyName",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "transferOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	}
]
  
  

export const LiquidityOpt = [
	{
	  "inputs": [],
	  "stateMutability": "nonpayable",
	  "type": "constructor"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_amount",
		  "type": "uint256"
		}
	  ],
	  "name": "LiquidityDepositSuccess",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_amountIn",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_amountOut",
		  "type": "uint256"
		}
	  ],
	  "name": "LiquiditySwapSuccess",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_amount",
		  "type": "uint256"
		}
	  ],
	  "name": "LiquidityWithdrawSuccess",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "previousOwner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "OwnershipTransferred",
	  "type": "event"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_token",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "_user",
		  "type": "address"
		}
	  ],
	  "name": "balance",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "governance",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "hubPool",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_token",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "_amount",
		  "type": "uint256"
		},
		{
		  "internalType": "bool",
		  "name": "_triggerEarn",
		  "type": "bool"
		}
	  ],
	  "name": "liquidityDeposit",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "owner",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "renounceOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "router",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_governance",
		  "type": "address"
		}
	  ],
	  "name": "setGovernance",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_hubPool",
		  "type": "address"
		}
	  ],
	  "name": "setHubPool",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_router",
		  "type": "address"
		}
	  ],
	  "name": "setRouter",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "transferOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_token",
		  "type": "address"
		}
	  ],
	  "name": "withdrawAll",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_tokenA",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "_tokenB",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "_amount",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_amountMin",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_deadline",
		  "type": "uint256"
		},
		{
		  "internalType": "bool",
		  "name": "_triggerEarn",
		  "type": "bool"
		},
		{
		  "internalType": "uint256",
		  "name": "_type",
		  "type": "uint256"
		}
	  ],
	  "name": "withdrawAndSwap",
	  "outputs": [
		{
		  "internalType": "uint256[]",
		  "name": "amounts",
		  "type": "uint256[]"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_token",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "_amount",
		  "type": "uint256"
		}
	  ],
	  "name": "withdrawToken",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	}
  ]
  
export const GovernanceHub = [
{
	"anonymous": false,
	"inputs": [
	{
		"indexed": true,
		"internalType": "address",
		"name": "previousOwner",
		"type": "address"
	},
	{
		"indexed": true,
		"internalType": "address",
		"name": "newOwner",
		"type": "address"
	}
	],
	"name": "OwnershipTransferred",
	"type": "event"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "user",
		"type": "address"
	}
	],
	"name": "addGovernance",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "amountADesired",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amountBDesired",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amountAMin",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amountBMin",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "deadline",
		"type": "uint256"
	}
	],
	"name": "addLiquidity",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "amountA",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amountB",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "liquidity",
		"type": "uint256"
	}
	],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "contract IERC20",
		"name": "_token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_profit",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "_earnLowerlimit",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "_min",
		"type": "uint256"
	},
	{
		"internalType": "bool",
		"name": "_withUpdate",
		"type": "bool"
	}
	],
	"name": "addPool",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_strategy",
		"type": "address"
	}
	],
	"name": "addStrategy",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}
	],
	"name": "adminWithdraw",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "user",
		"type": "address"
	}
	],
	"name": "containGovernance",
	"outputs": [
	{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_pid",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	},
	{
		"internalType": "address",
		"name": "_lp_token",
		"type": "address"
	}
	],
	"name": "depositStake",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "getGovernanceList",
	"outputs": [
	{
		"internalType": "address[]",
		"name": "",
		"type": "address[]"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_sid",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amount1",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amount2",
		"type": "uint256"
	}
	],
	"name": "govEarnLp",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_sid",
		"type": "uint256"
	},
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	}
	],
	"name": "govWithdrawLp",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"name": "governanceList",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}
	],
	"name": "inCaseTokensGetStuck",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "account",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}
	],
	"name": "inCaseTokensGetStuckWithAccount",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "liquidityAddress",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_vault",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}
	],
	"name": "liquidityWithdraw",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "owner",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "user",
		"type": "address"
	}
	],
	"name": "removeGovernance",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "liquidity",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amountAMin",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amountBMin",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "deadline",
		"type": "uint256"
	}
	],
	"name": "removeLiquidity",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "amountA",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amountB",
		"type": "uint256"
	}
	],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_strategy",
		"type": "address"
	}
	],
	"name": "removeStrategy",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "renounceOwnership",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address[]",
		"name": "userList",
		"type": "address[]"
	}
	],
	"name": "resetGovernance",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_controller",
		"type": "address"
	}
	],
	"name": "setController",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_earnLowerlimit",
		"type": "uint256"
	}
	],
	"name": "setEarnLowerlimit",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_hecoPool",
		"type": "address"
	}
	],
	"name": "setHecoPoolAddr",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "account",
		"type": "address"
	}
	],
	"name": "setHubGovernance",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_liquidityAddress",
		"type": "address"
	}
	],
	"name": "setLiquidityAddress",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_mdex_token_address",
		"type": "address"
	}
	],
	"name": "setMdexTokenAddr",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_pid",
		"type": "uint256"
	}
	],
	"name": "setMdxPid",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_min",
		"type": "uint256"
	}
	],
	"name": "setMin",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	}
	],
	"name": "setPause",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_profit",
		"type": "uint256"
	},
	{
		"internalType": "bool",
		"name": "_withUpdate",
		"type": "bool"
	}
	],
	"name": "setProfit",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "account",
		"type": "address"
	}
	],
	"name": "setRewardAccount",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_router",
		"type": "address"
	}
	],
	"name": "setRouterAddr",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address[]",
		"name": "_strategieList",
		"type": "address[]"
	}
	],
	"name": "setStrategyList",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_swapMining",
		"type": "address"
	}
	],
	"name": "setSwapMiningAddr",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_limit",
		"type": "uint256"
	}
	],
	"name": "setTotalAmountLimit",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_vault",
		"type": "address"
	}
	],
	"name": "setVault",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "newOwner",
		"type": "address"
	}
	],
	"name": "transferOwnership",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	}
	],
	"name": "withdrawAll",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}
	],
	"name": "withdrawHT",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	}
	],
	"name": "withdrawLp",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "targetAddress",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_pid",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}
	],
	"name": "withdrawStake",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"stateMutability": "payable",
	"type": "receive"
}
]  
export const StrategyMdex = [
	{
		"inputs": [],
		"name": "strategyName",
		"outputs": [
		  {
			"internalType": "string",
			"name": "",
			"type": "string"
		  }
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenA",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_tokenB",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_pid",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "accHistoryMDXReward",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "accTotalMdx",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amountADesired",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "amountBDesired",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "amountAMin",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "amountBMin",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "deadline",
				"type": "uint256"
			}
		],
		"name": "addLiquidity",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "amountA",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "amountB",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "liquidity",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "balance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "balanceOfInner",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "balanceOfToken",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "checkGovernance",
		"outputs": [],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "contain",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "controller",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pid",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_lp_token",
				"type": "address"
			}
		],
		"name": "depositStake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "factory",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getReserves",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "governance",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "hecoPool",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "inCaseTokensGetStuck",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount0",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_amount1",
				"type": "uint256"
			}
		],
		"name": "inputAmountGetLPAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "liquidity",
				"type": "uint256"
			}
		],
		"name": "inputLpGetTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mdexRouter",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mdexTokenAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pid",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pid",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "queryRewardByUser",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "liquidity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "amountAMin",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "amountBMin",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "deadline",
				"type": "uint256"
			}
		],
		"name": "removeLiquidity",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "amountA",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "amountB",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_controller",
				"type": "address"
			}
		],
		"name": "setController",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_governance",
				"type": "address"
			}
		],
		"name": "setGovernance",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_hecoPool",
				"type": "address"
			}
		],
		"name": "setHecoPoolAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_mdex_token_address",
				"type": "address"
			}
		],
		"name": "setMdexTokenAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "setPause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pid",
				"type": "uint256"
			}
		],
		"name": "setPid",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_router",
				"type": "address"
			}
		],
		"name": "setRouterAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_swap_mining_addr",
				"type": "address"
			}
		],
		"name": "setSwapMiningAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "swapMining",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenA",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenB",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "want",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdraw",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawHT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawMDXReward",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pid",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawStake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
]
export const ControllerHub = [
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_mdxToken",
		"type": "address"
	}
	],
	"stateMutability": "nonpayable",
	"type": "constructor"
},
{
	"anonymous": false,
	"inputs": [
	{
		"indexed": true,
		"internalType": "address",
		"name": "previousOwner",
		"type": "address"
	},
	{
		"indexed": true,
		"internalType": "address",
		"name": "newOwner",
		"type": "address"
	}
	],
	"name": "OwnershipTransferred",
	"type": "event"
},
{
	"anonymous": false,
	"inputs": [
	{
		"indexed": true,
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"indexed": true,
		"internalType": "address",
		"name": "user",
		"type": "address"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "comAmount",
		"type": "uint256"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "govAmount",
		"type": "uint256"
	}
	],
	"name": "Reward",
	"type": "event"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_token",
		"type": "address"
	}
	],
	"name": "accTotalMdx",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "uint256",
		"name": "_sid",
		"type": "uint256"
	}
	],
	"name": "accTotalMdxWithId",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "accTotalStrategyMdx",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	},
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_strategy",
		"type": "address"
	}
	],
	"name": "addStrategy",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_token",
		"type": "address"
	}
	],
	"name": "balanceOfToken",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "checkGovernance",
	"outputs": [],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "compound",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "compoundHub",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "compoundLimit",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_strategy",
		"type": "address"
	}
	],
	"name": "containStrategy",
	"outputs": [
	{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_token",
		"type": "address"
	}
	],
	"name": "earn",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "uint256",
		"name": "_sid",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amount1",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "amount2",
		"type": "uint256"
	}
	],
	"name": "govEarn",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "uint256",
		"name": "_sid",
		"type": "uint256"
	},
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	}
	],
	"name": "govWithdraw",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "governance",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "account",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}
	],
	"name": "inCaseTokensGetStuck",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "mdxToken",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "owner",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "paused",
	"outputs": [
	{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_strategy",
		"type": "address"
	}
	],
	"name": "removeStrategy",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "renounceOwnership",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "rewardAccount",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "addr",
		"type": "address"
	}
	],
	"name": "setCompoundHub",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	}
	],
	"name": "setCompoundLimit",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_governance",
		"type": "address"
	}
	],
	"name": "setGovernance",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_mdxToken",
		"type": "address"
	}
	],
	"name": "setMdexTokenAddr",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "setPause",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_rewardAccount",
		"type": "address"
	}
	],
	"name": "setRewardAccount",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address[]",
		"name": "_strategieList",
		"type": "address[]"
	}
	],
	"name": "setStrategyList",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_vault",
		"type": "address"
	}
	],
	"name": "setVault",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"name": "strategieList",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "strategyLength",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "newOwner",
		"type": "address"
	}
	],
	"name": "transferOwnership",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "vault",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "vaults",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_token",
		"type": "address"
	}
	],
	"name": "withdrawAll",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}
	],
	"name": "withdrawLp",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "user",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "userLpPending",
		"type": "uint256"
	},
	{
		"internalType": "uint256",
		"name": "govPending",
		"type": "uint256"
	}
	],
	"name": "withdrawPending",
	"outputs": [
	{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}
	],
	"stateMutability": "nonpayable",
	"type": "function"
}
]

export const CompoundHub = [
{
	"inputs": [],
	"stateMutability": "nonpayable",
	"type": "constructor"
},
{
	"anonymous": false,
	"inputs": [
	{
		"indexed": true,
		"internalType": "address",
		"name": "previousOwner",
		"type": "address"
	},
	{
		"indexed": true,
		"internalType": "address",
		"name": "newOwner",
		"type": "address"
	}
	],
	"name": "OwnershipTransferred",
	"type": "event"
},
{
	"anonymous": false,
	"inputs": [
	{
		"indexed": true,
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "cpoolAmount",
		"type": "uint256"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "hpoolAmount",
		"type": "uint256"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "realAmount",
		"type": "uint256"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "reward",
		"type": "uint256"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "cpoolAmountPer",
		"type": "uint256"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "blockNum",
		"type": "uint256"
	}
	],
	"name": "UpdatePool",
	"type": "event"
},
{
	"inputs": [],
	"name": "balanceOf",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "checkController",
	"outputs": [],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "checkGovernance",
	"outputs": [],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "compoundStorage",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "compoundStrategy",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "controller",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	}
	],
	"name": "decLp",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	}
	],
	"name": "depositLp",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"name": "getApy",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	}
	],
	"name": "getUserApy",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "governance",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "account",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "_token",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}
	],
	"name": "inCaseTokensGetStuck",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "vault",
		"type": "address"
	}
	],
	"name": "massUpdatePools",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "mdxApy",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "owner",
	"outputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "paused",
	"outputs": [
	{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "token",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "user",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "vault",
		"type": "address"
	}
	],
	"name": "pending",
	"outputs": [
	{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}
	],
	"stateMutability": "view",
	"type": "function"
},
{
	"inputs": [],
	"name": "renounceOwnership",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_storage",
		"type": "address"
	}
	],
	"name": "setCompoundStorage",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "addr",
		"type": "address"
	}
	],
	"name": "setCompoundStrategy",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_controller",
		"type": "address"
	}
	],
	"name": "setController",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "_governance",
		"type": "address"
	}
	],
	"name": "setGovernance",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "setPause",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "newOwner",
		"type": "address"
	}
	],
	"name": "transferOwnership",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "uint256",
		"name": "hpoolId",
		"type": "uint256"
	},
	{
		"internalType": "address",
		"name": "vault",
		"type": "address"
	}
	],
	"name": "updatePool",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [
	{
		"internalType": "address",
		"name": "",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	}
	],
	"name": "withdrawLp",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"inputs": [],
	"name": "withdrawMDXReward",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
}
]  
  

export const HubPool = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_controller",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "pid",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Deposit",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "pid",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "EmergencyWithdraw",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "pid",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Withdraw",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "TokenOfPid",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract IERC20",
				"name": "_token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_profit",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_earnLowerlimit",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_min",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "_withUpdate",
				"type": "bool"
			}
		],
		"name": "add",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "approveCtr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "available",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "checkGovernance",
		"outputs": [],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "controller",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "depositAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pid",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "depositWithPid",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "earn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "userAddress",
				"type": "address"
			}
		],
		"name": "earned",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pid",
				"type": "uint256"
			}
		],
		"name": "emergencyWithdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "getApy",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "userAddress",
				"type": "address"
			}
		],
		"name": "getDepositAsset",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "getMdxBlockReward",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "getPoolId",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "govTotalProfit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "govTotalSendProfit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "governance",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "inCaseTokensGetStuck",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "liquidityAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "liquidityWithdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "massUpdatePools",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pid",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "pending",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "poolInfo",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "lastRewardBlock",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "accMdxPerShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "govAccMdxPerShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "accMdxShare",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "totalAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "totalAmountLimit",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "profit",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "earnLowerlimit",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "min",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "lastRewardBlockProfit",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "poolLength",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "anchorToken",
				"type": "address"
			}
		],
		"name": "rate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "amountOut",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "anchorToken",
				"type": "address"
			}
		],
		"name": "rateAndReward",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "token",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "rate",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "tvl",
						"type": "uint256"
					}
				],
				"internalType": "struct HubPool.RateAndTvl[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "anchorToken",
				"type": "address"
			}
		],
		"name": "rateAndTvl",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "token",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "rate",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "tvl",
						"type": "uint256"
					}
				],
				"internalType": "struct HubPool.RateAndTvl[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_controller",
				"type": "address"
			}
		],
		"name": "setController",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_earnLowerlimit",
				"type": "uint256"
			}
		],
		"name": "setEarnLowerlimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_governance",
				"type": "address"
			}
		],
		"name": "setGovernance",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_liquidityAddress",
				"type": "address"
			}
		],
		"name": "setLiquidityAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_min",
				"type": "uint256"
			}
		],
		"name": "setMin",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "setPause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_profit",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "_withUpdate",
				"type": "bool"
			}
		],
		"name": "setProfit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_swapMining",
				"type": "address"
			}
		],
		"name": "setSwapMiningAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_limit",
				"type": "uint256"
			}
		],
		"name": "setTotalAmountLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "swapMining",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pid",
				"type": "uint256"
			}
		],
		"name": "updatePool",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "userInfo",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "rewardDebt",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "govRewardDebt",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "userTotalProfit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "userTotalSendProfit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "withdrawAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawHT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pid",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawWithPid",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
]

export const erc20ABI = [{
	"constant": false,
	"inputs": [{
		"name": "_spender",
		"type": "address"
	}, {
		"name": "_value",
		"type": "uint256"
	}],
	"name": "approve",
	"outputs": [{
		"name": "success",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"name": "_to",
		"type": "address"
	}, {
		"name": "_value",
		"type": "uint256"
	}],
	"name": "showMeTheMoney",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"name": "_to",
		"type": "address"
	}, {
		"name": "_value",
		"type": "uint256"
	}],
	"name": "transfer",
	"outputs": [{
		"name": "success",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"name": "_from",
		"type": "address"
	}, {
		"name": "_to",
		"type": "address"
	}, {
		"name": "_value",
		"type": "uint256"
	}],
	"name": "transferFrom",
	"outputs": [{
		"name": "success",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"anonymous": false,
	"inputs": [{
		"indexed": true,
		"name": "_from",
		"type": "address"
	}, {
		"indexed": true,
		"name": "_to",
		"type": "address"
	}, {
		"indexed": false,
		"name": "_value",
		"type": "uint256"
	}],
	"name": "Transfer",
	"type": "event"
},
{
	"anonymous": false,
	"inputs": [{
		"indexed": true,
		"name": "_owner",
		"type": "address"
	}, {
		"indexed": true,
		"name": "_spender",
		"type": "address"
	}, {
		"indexed": false,
		"name": "_value",
		"type": "uint256"
	}],
	"name": "Approval",
	"type": "event"
}, {
	"constant": true,
	"inputs": [{
		"name": "_owner",
		"type": "address"
	}, {
		"name": "_spender",
		"type": "address"
	}],
	"name": "allowance",
	"outputs": [{
		"name": "remaining",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [{
		"name": "_owner",
		"type": "address"
	}],
	"name": "balanceOf",
	"outputs": [{
		"name": "balance",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "decimals",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "name",
	"outputs": [{
		"name": "",
		"type": "string"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "symbol",
	"outputs": [{
		"name": "",
		"type": "string"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "totalSupply",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}
]

export const USDTABI = [{
	"constant": true,
	"inputs": [],
	"name": "name",
	"outputs": [{
		"name": "",
		"type": "string"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "_upgradedAddress",
		"type": "address"
	}],
	"name": "deprecate",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "_spender",
		"type": "address"
	}, {
		"name": "_value",
		"type": "uint256"
	}],
	"name": "approve",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "deprecated",
	"outputs": [{
		"name": "",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "_evilUser",
		"type": "address"
	}],
	"name": "addBlackList",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "totalSupply",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "_from",
		"type": "address"
	}, {
		"name": "_to",
		"type": "address"
	}, {
		"name": "_value",
		"type": "uint256"
	}],
	"name": "transferFrom",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "upgradedAddress",
	"outputs": [{
		"name": "",
		"type": "address"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [{
		"name": "",
		"type": "address"
	}],
	"name": "balances",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "decimals",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "maximumFee",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "_totalSupply",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": false,
	"inputs": [],
	"name": "unpause",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": true,
	"inputs": [{
		"name": "_maker",
		"type": "address"
	}],
	"name": "getBlackListStatus",
	"outputs": [{
		"name": "",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [{
		"name": "",
		"type": "address"
	}, {
		"name": "",
		"type": "address"
	}],
	"name": "allowed",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "paused",
	"outputs": [{
		"name": "",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [{
		"name": "who",
		"type": "address"
	}],
	"name": "balanceOf",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": false,
	"inputs": [],
	"name": "pause",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "getOwner",
	"outputs": [{
		"name": "",
		"type": "address"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "owner",
	"outputs": [{
		"name": "",
		"type": "address"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "symbol",
	"outputs": [{
		"name": "",
		"type": "string"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "_to",
		"type": "address"
	}, {
		"name": "_value",
		"type": "uint256"
	}],
	"name": "transfer",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "newBasisPoints",
		"type": "uint256"
	}, {
		"name": "newMaxFee",
		"type": "uint256"
	}],
	"name": "setParams",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "amount",
		"type": "uint256"
	}],
	"name": "issue",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "amount",
		"type": "uint256"
	}],
	"name": "redeem",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": true,
	"inputs": [{
		"name": "_owner",
		"type": "address"
	}, {
		"name": "_spender",
		"type": "address"
	}],
	"name": "allowance",
	"outputs": [{
		"name": "remaining",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "basisPointsRate",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": true,
	"inputs": [{
		"name": "",
		"type": "address"
	}],
	"name": "isBlackListed",
	"outputs": [{
		"name": "",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "_clearedUser",
		"type": "address"
	}],
	"name": "removeBlackList",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": true,
	"inputs": [],
	"name": "MAX_UINT",
	"outputs": [{
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "newOwner",
		"type": "address"
	}],
	"name": "transferOwnership",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"constant": false,
	"inputs": [{
		"name": "_blackListedUser",
		"type": "address"
	}],
	"name": "destroyBlackFunds",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"name": "_initialSupply",
		"type": "uint256"
	}, {
		"name": "_name",
		"type": "string"
	}, {
		"name": "_symbol",
		"type": "string"
	}, {
		"name": "_decimals",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "constructor"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"name": "amount",
		"type": "uint256"
	}],
	"name": "Issue",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"name": "amount",
		"type": "uint256"
	}],
	"name": "Redeem",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"name": "newAddress",
		"type": "address"
	}],
	"name": "Deprecate",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"name": "feeBasisPoints",
		"type": "uint256"
	}, {
		"indexed": false,
		"name": "maxFee",
		"type": "uint256"
	}],
	"name": "Params",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"name": "_blackListedUser",
		"type": "address"
	}, {
		"indexed": false,
		"name": "_balance",
		"type": "uint256"
	}],
	"name": "DestroyedBlackFunds",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"name": "_user",
		"type": "address"
	}],
	"name": "AddedBlackList",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"name": "_user",
		"type": "address"
	}],
	"name": "RemovedBlackList",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": true,
		"name": "owner",
		"type": "address"
	}, {
		"indexed": true,
		"name": "spender",
		"type": "address"
	}, {
		"indexed": false,
		"name": "value",
		"type": "uint256"
	}],
	"name": "Approval",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": true,
		"name": "from",
		"type": "address"
	}, {
		"indexed": true,
		"name": "to",
		"type": "address"
	}, {
		"indexed": false,
		"name": "value",
		"type": "uint256"
	}],
	"name": "Transfer",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [],
	"name": "Pause",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [],
	"name": "Unpause",
	"type": "event"
}]

export const earnContractABI = [{
	"inputs": [{
		"internalType": "address",
		"name": "_token",
		"type": "address"
	}],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "constructor"
},
{
	"anonymous": false,
	"inputs": [{
		"indexed": true,
		"internalType": "address",
		"name": "owner",
		"type": "address"
	},
	{
		"indexed": true,
		"internalType": "address",
		"name": "spender",
		"type": "address"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "value",
		"type": "uint256"
	}
	],
	"name": "Approval",
	"type": "event"
},
{
	"anonymous": false,
	"inputs": [{
		"indexed": true,
		"internalType": "address",
		"name": "from",
		"type": "address"
	},
	{
		"indexed": true,
		"internalType": "address",
		"name": "to",
		"type": "address"
	},
	{
		"indexed": false,
		"internalType": "uint256",
		"name": "value",
		"type": "uint256"
	}
	],
	"name": "Transfer",
	"type": "event"
},
{
	"payable": true,
	"stateMutability": "payable",
	"type": "fallback"
},
{
	"constant": true,
	"inputs": [{
		"internalType": "address",
		"name": "owner",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "spender",
		"type": "address"
	}
	],
	"name": "allowance",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "address",
		"name": "spender",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	}
	],
	"name": "approve",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "available",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "balance",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": true,
	"inputs": [{
		"internalType": "address",
		"name": "account",
		"type": "address"
	}],
	"name": "balanceOf",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "controller",
	"outputs": [{
		"internalType": "address",
		"name": "",
		"type": "address"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "decimals",
	"outputs": [{
		"internalType": "uint8",
		"name": "",
		"type": "uint8"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "address",
		"name": "spender",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "subtractedValue",
		"type": "uint256"
	}
	],
	"name": "decreaseAllowance",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}],
	"name": "deposit",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [],
	"name": "depositAll",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [],
	"name": "depositETH",
	"outputs": [],
	"payable": true,
	"stateMutability": "payable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [],
	"name": "earn",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "earnLowerlimit",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "getPricePerFullShare",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "governance",
	"outputs": [{
		"internalType": "address",
		"name": "",
		"type": "address"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "address",
		"name": "spender",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "addedValue",
		"type": "uint256"
	}
	],
	"name": "increaseAllowance",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "max",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "min",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "name",
	"outputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "address",
		"name": "_controller",
		"type": "address"
	}],
	"name": "setController",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "uint256",
		"name": "_earnLowerlimit",
		"type": "uint256"
	}],
	"name": "setEarnLowerlimit",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "address",
		"name": "_governance",
		"type": "address"
	}],
	"name": "setGovernance",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "uint256",
		"name": "_min",
		"type": "uint256"
	}],
	"name": "setMin",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "symbol",
	"outputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "token",
	"outputs": [{
		"internalType": "contract IERC20",
		"name": "",
		"type": "address"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": true,
	"inputs": [],
	"name": "totalSupply",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"payable": false,
	"stateMutability": "view",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "address",
		"name": "recipient",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	}
	],
	"name": "transfer",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "address",
		"name": "sender",
		"type": "address"
	},
	{
		"internalType": "address",
		"name": "recipient",
		"type": "address"
	},
	{
		"internalType": "uint256",
		"name": "amount",
		"type": "uint256"
	}
	],
	"name": "transferFrom",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "uint256",
		"name": "_shares",
		"type": "uint256"
	}],
	"name": "withdraw",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [],
	"name": "withdrawAll",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [],
	"name": "withdrawAllETH",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
},
{
	"constant": false,
	"inputs": [{
		"internalType": "uint256",
		"name": "_shares",
		"type": "uint256"
	}],
	"name": "withdrawETH",
	"outputs": [],
	"payable": false,
	"stateMutability": "nonpayable",
	"type": "function"
}
]

