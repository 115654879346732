/* eslint-disable */
import React, {  } from "react";
import { useSelector } from 'react-redux';
import {
    withRouter
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './H5Menu.css';

export default withRouter(function H5Menu({ history }) {
    const { t, i18n } = useTranslation();
    const currentPathName = history.location.pathname;
    const theme = useSelector(state => state.common.theme);
    return (
        <div style={{
            padding: "0 16px",
            boxSizing: "border-box",
            position: "fixed",
            bottom: "32px",
            width: "100%",
            zIndex: "1000"
        }}>
            <div className="footer__menu" style={{
                background: "rgba(255,255,255,.98)",
                border: "1px solid #FFFFFF",
                boxShadow: "0 2px 8px 0 rgba(0,0,0,0.08)",
                borderRadius: "16px",
                display: "flex",
                justifyContent: "space-between",
                height: "48px",
            }}>
                <div className={`menu__item${currentPathName==='/'?' menu__item--actived':''}`} onClick={() => {
                    history.push('/')
                    window.location.reload();
                }}>
                    <i className={`i-${theme}`}></i>
                    <p className={`p-${theme}`}>{t('Nav-Home')}</p>
                </div>
                <div className={`menu__item${currentPathName==='/vault'?' menu__item--actived':''}`} onClick={() => {
                    history.push('/vault');
                    window.location.reload();
                }}>
                    <i className={`i-${theme}`}></i>
                    <p className={`p-${theme}`}>{t('Nav-Danbi')}</p>
                </div>
                <div className={`menu__item${currentPathName==='/lp'?' menu__item--actived':''} menu__item-${theme}`} onClick={() => {
                    history.push('/lp');
                    window.location.reload();
                }}>
                    <i className={`i-${theme}`}></i>
                    <p className={`p-${theme}`}>{t('Nav-Lp')}</p>
                    <strong>New</strong>
                </div>
            </div>
        </div>
    )
})

