import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import {
    withRouter
} from "react-router-dom";
import DropDown from "../DropDown/DropDown.js"
import { makeStyles } from "@material-ui/core/styles";
// 多语言
import { useTranslation } from 'react-i18next';
// core components
import styles from "assets/jss/components/navListStyle.js";
import "./NavList.css";
import { primaryColor } from "assets/jss/material-kit-pro-react";
const useStyles = makeStyles(styles);
export default withRouter(function NavList({ history }) {
    const theme = useSelector(state => state.common.theme);
    const currentPathName = history.location.pathname;
    const classes = useStyles();
    const [danbiActive, setDanbiActive] = useState(false);
    const [lpActive, setLpActive] = useState(false);
    const [lng, setLanguage] = useState('zh');
    const [opened, setOpened] = useState(false);
    const { t, i18n } = useTranslation();
    const footerLinkArrHecoZh = [
        { content: 'Tutorial-1', href: 'https://www.yuque.com/coinwind/tutorial_cn/walletcreate' },
        { content: 'Tutorial-2', href: 'https://www.yuque.com/coinwind/tutorial_cn/singlefarm' },
        { content: 'Tutorial-3', href: 'https://www.yuque.com/coinwind/tutorial_cn/lpsinglefarm' },
        { content: 'Tutorial-4', href: 'https://www.yuque.com/coinwind/tutorial_cn/contactus' },
    ];
    const footerLinkArrHecoEn = [
        { content: 'Tutorial-1', href: 'https://www.yuque.com/coinwind/tutorial_en/walletcreate' },
        { content: 'Tutorial-2', href: 'https://www.yuque.com/coinwind/tutorial_en/singlefarm' },
        { content: 'Tutorial-3', href: 'https://www.yuque.com/coinwind/tutorial_en/lpsinglefarm' },
        { content: 'Tutorial-4', href: 'https://www.yuque.com/coinwind/tutorial_en/contactus' },
    ];
    const footerLinkArrBscZh = [
        { content: 'Tutorial-1', href: 'https://www.yuque.com/coinwind/tutorial_cn/walletcreate' },
        { content: 'Tutorial-2', href: 'https://www.yuque.com/coinwind/tutorial_cn/singlefarm' },
        { content: 'Tutorial-3', href: 'https://www.yuque.com/coinwind/tutorial_cn/lpsinglefarm' },
        { content: 'Tutorial-4', href: 'https://www.yuque.com/coinwind/tutorial_cn/contactus' },
    ];
    const footerLinkArrBscEn = [
        { content: 'Tutorial-1', href: 'https://www.yuque.com/coinwind/tutorial_en/walletcreate' },
        { content: 'Tutorial-2', href: 'https://www.yuque.com/coinwind/tutorial_en/singlefarm' },
        { content: 'Tutorial-3', href: 'https://www.yuque.com/coinwind/tutorial_en/lpsinglefarm' },
        { content: 'Tutorial-4', href: 'https://www.yuque.com/coinwind/tutorial_en/contactus' },
    ];

    let tutorilList;
    if (i18n.language === 'zh' && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 128) {
        tutorilList = footerLinkArrHecoZh;
    } else if (i18n.language === 'en' && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 128) {
        tutorilList = footerLinkArrHecoEn;
    } else if (i18n.language === 'zh' && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 56) {
        tutorilList = footerLinkArrBscZh;
    } else {
        tutorilList = footerLinkArrBscEn;
    }
    // 语言列表
    const dropDownList = [
        {
            text: "中文",
            id: "1",
        },
        {
            text: "English",
            id: "2",
        },
    ]
    // 切换语言回调
    function getLanguage(props) {
        switch (props) {
            case 'English':
                return i18n.changeLanguage('en').then(() => {
                    setLanguage(props)
                })
            case '中文':
                return i18n.changeLanguage('zh').then(() => {
                    setLanguage(props)
                })
            default:
                return
        }
    }
    // 切换语言
    const switchLanguage = () => {
        switch (i18n.language) {
            case 'zh':
            case 'zh-CN':
                return '中文'
            case 'en':
                return 'English'
        }
    }
    
    useEffect(() => {
        const lng = switchLanguage()
        setLanguage(lng);
    }, [switchLanguage]);
    return (
        <div className={classes.navContainer}>
            <div className={classes.navWapper}>
                <ul className={classes.navList}>
                    <li className={classes.navListItem}>
                        <div className={`${classes.navListItemContainer} navlist__item-container ${currentPathName==='/'?classes.navListItemActived:''} ${currentPathName==='/'?'navlist__item-actived':''}`}
                            onClick={() => {
                                history.push('/');
                                window.location.reload();
                            }}>
                            <i className={`${classes.navIcon} nav__index nav__index-${theme}`}></i>
                            {/* <img src={require("../../images/icon-index.svg")}></img> */}
                            {t('Nav-Home')}
                            {/* 竖线 */}
                            <span></span>
                        </div>
                    </li>
                    <li className={classes.navListItem}>
                        <div
                            className={`${classes.navListItemContainer} navlist__item-container`}
                            onClick={() => {
                                setDanbiActive(!danbiActive)
                            }}>
                            <i className={`${classes.navIcon} nav__danbi nav__danbi-${theme}`}></i>
                            {/* <img className={classes.navIcon} src={require("../../images/icon-high.svg")}></img> */}
                            <span>{t('Nav-Danbi')}</span>
                            <strong style={{
                                color: primaryColor[2],
                                width: "0",
                                height: "0",
                                display: "inline-block",
                                borderBottom: "4px solid",
                                transition: "all 150ms ease-in",
                                borderLeft: "4px solid transparent",
                                marginLeft: "4px",
                                borderRight: "4px solid transparent",
                                verticalAlign: "middle",
                                position: "absolute",
                                right: "32px",
                                top: "30px",
                                transform: danbiActive ? "rotate(180deg)" : ''
                            }}></strong>
                        </div>
                        <ul className={classes.navListItemOptionContainer} style={{
                            maxHeight: danbiActive ? "0px" : "100px"
                        }}>
                            <li className={`${classes.navListItemOptionItem} ${currentPathName === '/vault' ? classes.navListItemActived : ''}`} onClick={() => {
                                history.push('/vault')
                                window.location.reload();
                            }}>
                                {t('v2-ui-22')}
                                {/* 竖线 */}
                                <span></span>
                            </li>
                            {
                                theme === 'heco' ? <li className={`${classes.navListItemOptionItem}`} onClick={() => window.open('https://v1.coinwind.com/')}>
                                    {t('v2-ui-v1')}
                                </li> : null
                            }
                        </ul>
                    </li>
                    {/* lp 暂时不上 */}
                    <li className={classes.navListItem}>
                        <div className={`${classes.navListItemContainer} ${classes.lpIcon} navlist__item-container ${currentPathName==='/lp'?classes.navListItemActived:''} ${currentPathName==='/lp'?'navlist__item-actived':''}`}
                            onClick={() => {
                            // setLpActive(!lpActive)
                            history.push('/lp')
                            window.location.reload();
                        }}>
                            <i className={`${classes.navIcon} nav__lp nav__lp-${theme}`}></i>
                            {t('Nav-Lp')}
                            <strong>New</strong>
                            {/* 竖线 */}
                            <span></span>
                            {/* <strong style={{
                                color: primaryColor[2],
                                width: "0",
                                height: "0",
                                display: "inline-block",
                                borderTop: "4px solid",
                                transition: "all 150ms ease-in",
                                borderLeft: "4px solid transparent",
                                marginLeft: "4px",
                                borderRight: "4px solid transparent",
                                verticalAlign: "middle",
                                position: "absolute",
                                right: "32px",
                                top: "30px",
                                transform: lpActive ? "rotate(180deg)" : ''
                            }}></strong> */}
                        </div>
                        {/* <ul className={classes.navListItemOptionContainer} style={{
                            maxHeight: lpActive ? "0" : "100px"
                        }}>
                            <li className={`${classes.navListItemOptionItem} ${currentPathName === '/lp' ? classes.navListItemActived : ''}`} onClick={() => history.push('/lp')}>
                                {t('v2-ui-23')}
                            </li>
                        </ul> */}
                    </li>
                </ul>
                <div className="nav__bottom">
                    <div className="nav__tutoril">
                        {
                            tutorilList.map((item, ind) => {
                                return (
                                    <a className={`tutoril__item ${classes.tutorilItem}`} href={item.href} target="_blank" key={ind}>
                                        <span>{t(item.content)}</span>
                                        <img src={require('../../images/icon-more.svg')} alt="" />
                                    </a>
                                )
                            })
                        }
                    </div>
                    <div className="nav__language-container" onClick={() => {
                        setOpened(!opened);
                    }}>
                        <i className={`language__icon language__icon-${theme}`}></i>
                        <DropDown
                            className={`nav__language nav__language-${theme}`}
                            buttonText={lng}
                            opened={opened}
                            dropDownList={dropDownList}
                            position="top"
                            buttonClickCallback={(props) => {
                                getLanguage(props);
                                setOpened(!opened);
                            }}
                        >
                        </DropDown>
                        <strong style={{
                            color: primaryColor[2],
                            width: "0",
                            height: "0",
                            display: "inline-block",
                            borderTop: "4px solid",
                            transition: "all 150ms ease-in",
                            borderLeft: "4px solid transparent",
                            marginLeft: "4px",
                            borderRight: "4px solid transparent",
                            verticalAlign: "middle",
                            position: "absolute",
                            right: "32px",
                            top: "30px",
                            transform: opened ? "rotate(180deg)" : ''
                        }}></strong>
                    </div>
                </div>
            </div>
        </div>
    );
})
