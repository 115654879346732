/* eslint-disable */
import React, { useEffect, useState } from "react";
import Hidden from "@material-ui/core/Hidden";
import { useTranslation } from 'react-i18next';


const footerLinkArrHecoZh = [
    { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_zh.pdf'},
    { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_zh.pdf'},
];
const footerLinkArrHecoEn = [
    { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_en.pdf'},
    { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_en.pdf'},
];
const footerLinkArrBscZh = [
    { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_zh.pdf'},
    { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_zh.pdf'},
];
const footerLinkArrBscEn = [
    { content: 'Audit-Report1', href: 'https://www.coinwind.com/BEOSIN_en.pdf'},
    { content: 'Audit-Report2', href: 'https://www.coinwind.com/flyhub_en.pdf'},
];

export default function FooterLinks(props) {
    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const [isWechat, setIsWechat] = useState(false);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <div className="footer">
            {/* pc */}
            <Hidden smDown implementation="css">
                <div className="footer__wrapper">
                    <div className="footer__left">
                        <img className="footer__left-logo" src={require('../../images/logo-hui.svg')} alt=""/>
                        {
                            (i18n.language === 'zh' || i18n.language === 'zh_CN') && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 128 ?
                            footerLinkArrHecoZh.map((item, ind) => {
                                return (
                                    <a className="footer__left-item" target="_blank" key={ind} href={item.href}>{t(item.content)}</a>
                                )
                            })
                            :
                            (i18n.language === 'en' || i18n.language === 'en_US') && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 128 ?
                            footerLinkArrHecoEn.map((item, ind) => {
                                return (
                                    <a className="footer__left-item" target="_blank" key={ind} href={item.href}>{t(item.content)}</a>
                                )
                            })
                            :
                            (i18n.language === 'zh' || i18n.language === 'zh_CN') && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 56 ?
                            footerLinkArrBscZh.map((item, ind) => {
                                return (
                                    <a className="footer__left-item" target="_blank" key={ind} href={item.href}>{t(item.content)}</a>
                                )
                            })
                            :
                            footerLinkArrBscEn.map((item, ind) => {
                                return (
                                    <a className="footer__left-item" target="_blank" key={ind} href={item.href}>{t(item.content)}</a>
                                )
                            })
                        }
                    </div>
                    <div className="footer__right">
                        <span>{t('Footer-us')}</span>
                        <div className="footer__right-img-box" style={{marginRight: "56px"}}>
                            <i className="footer__right-img-wechat"></i>
                            <img src={require('../../images/Wechat.jpeg')} alt="" className="footer__right-hover"/>
                        </div>
                        <div className="footer__right-img-box">
                            <a href="https://twitter.com/coinwind_com" target="_blank">
                                <i className="footer__right-img-tw"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </Hidden>
            {/* h5 */}
            <Hidden mdUp implementation="css">
                <div className="footer__wrapper">
                    <div className="footer__right-img-box" style={{marginRight: "64px"}}>
                        <i className="footer__right-img-wechat" onClick={() => setIsWechat(!isWechat)}></i>
                        <img src={require('../../images/Wechat.jpeg')} alt="" className={`footer__right-hover ${isWechat ? 'footer__right-show' : ''}`}/>
                    </div>
                    <div className="footer__right-img-box">
                        <a href="https://twitter.com/coinwind_com" target="_blank">
                            <i className="footer__right-img-tw"></i>
                        </a>
                    </div>
                </div>
            </Hidden>
        </div>
    )
}

