import { primaryColor, whiteColor } from "./material-kit-pro-react";

const HomeStyle = theme => ({
    homeContainer: {
        paddingTop: "20px",
        [theme.breakpoints.down('sm')]: {
            paddingTop: "16px",
        },
    },
    homeBanner: {
        width: "100%",
        height: "240px",
        // padding: "32px 0",
        // display: "flex",
        marginBottom: "18px",
        [theme.breakpoints.down('sm')]: {
            height: "140px",
            marginBottom: "32px",
        },
    },
    // bannerLeft: {
    //     maxWidth: "550px",
    //     paddingLeft: "80px",
    //     // boxSizing: "border-box",
    //     [theme.breakpoints.down('sm')]: {
    //         paddingLeft: "32px",
    //     },
    // },
    // bannerLeftTitle: {
    //     fontFamily: "YouSheBiaoTiHei",
    //     fontSize: "54px",
    //     color: "#131D32",
    //     fontWeight: "400",
    //     "&>span": {
    //         color: primaryColor[0],
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //         fontSize: "48px",
    //     },
    // },
    // bannerLeftText: {
    //     fontFamily: "MicrosoftYaHei;",
    //     fontSize: "18px",
    //     color: "#8391A8",
    //     fontWeight: "400",
    //     lineHeight: "32px",
    //     "&>span": {
    //         color: primaryColor[0],
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //         fontSize: "28px",
    //     },
    // },
    homeTab: {
        // marginTop: "18px",
        marginBottom: "18px",
        [theme.breakpoints.down('sm')]: {
            // marginTop: "16px",
            marginBottom: "16px",
            "& .tab__item1": {
                display: "none"
            }
        },
    },
    tabItem: {
        padding: "20px 0 30px",
        width: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "33.3%",
            padding: "20px 0 16px",
        },
    },
    tabText: {
        marginTop: "16px",
        // fontFamily: "PingFangSC-Medium",
        fontSize: "16px",
        color: "#666666",
        fontWeight: "500",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
            fontSize: "12px",
            color: "#8391A8",
        },
    },
    tabImg: {
        width: "auto",
        height: "48px",
        display: "block",
        [theme.breakpoints.down('sm')]: {
            width: "auto",
            height: "30px",
            display: "block",
        },
    },
    homeAudit: {
        marginBottom: "20px",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "16px",
        },
    },
    auditTitle: {
        textAlign: "center",
        // fontFamily: "MicrosoftYaHei-Bold",
        fontSize: "18px",
        color: "#131D32",
        lineHeight: "20px",
        fontWeight: "700",
        marginBottom: "28px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
            lineHeight: "18px",
            marginBottom: "20px",
        },
    },
    auditImg: {
        display: "flex",
        justifyContent: "center",
    },
    auditImgItem: {
        width: "auto",
        height: "45px",
        display: "block",
        [theme.breakpoints.down('sm')]: {
            height: "34px",
        },
    },
    auditImgOne: {
        marginRight: "96px",
        [theme.breakpoints.down('sm')]: {
            marginRight: "34px",
        },
    },
    homePartner: {
        width: "100%",
        padding: "32px 80px 18px",
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            padding: "20px 16px 0",
        },
    },
    partnerImgContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    partnerImgItem: {
        width: "20%",
        // display: "flex",
        // justifyContent: "center",
        paddingBottom: "30px",
        "&:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10), &:nth-of-type(11)": {
            width: "15%",
        },
        "& img": {
            width: "80%",
            display: "block",
            margin: "0 auto",
        },
        "&:nth-of-type(1) img": {
            width: "90%",
        },
        "&:nth-of-type(2) img": {
            width: "66%",
        },
        "&:nth-of-type(4) img": {
            width: "66%",
        },
        "&:nth-of-type(5) img": {
            width: "60%",
        },
        "&:nth-of-type(6) img": {
            width: "100%",
        },
        "&:nth-of-type(7) img": {
            width: "90%",
        },
        "&:nth-of-type(8) img": {
            width: "82%",
        },
        "&:nth-of-type(9) img": {
            width: "94%",
        },
        "&:nth-of-type(10) img": {
            width: "64%",
        },
        "&:nth-of-type(11) img": {
            width: "76%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "33.3%",
            paddingBottom: "24px",
            "&:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10), &:nth-of-type(11)": {
                width: "33.3%",
            },
            "&:nth-of-type(8) img": {
                width: "74%",
            },
            "&:nth-of-type(9) img": {
                width: "86%",
            },
            "&:nth-of-type(10) img": {
                width: "56%",
            },
            "&:nth-of-type(11) img": {
                width: "66%",
            },
        },
    },
    homeProfit: {
        padding: "28px 40px 32px",
        marginBottom: "22px",
        [theme.breakpoints.down('sm')]: {
            padding: "16px",
        },
    },
    profit: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            display: "unset",
        },
    },
    profitItem: {
        width: "50%",
        height: "98px",
        backgroundColor: primaryColor[7],
        borderRadius: "8px",
        padding: "22px 40px",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        color: "#8391A8",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            textAlign: "center",
            padding: "16px 0",
            justifyContent: "center",
            height: "auto",
        },
        "&>div": {
            margin: "0 auto",
            textAlign: "center",
        }
    },
    profitTitle: {
        fontSize: "14px",
        color: "#8391a8",
        marginBottom: "10px",
        // fontFamily: "MicrosoftYaHei-Bold",
        textAlign: "center",
        lineHeight: "22px",
        fontWeight: "700",
    },
    profitRightContent: {
        position: "absolute",
        right: "40px",
        bottom: "8px",
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
    },
    profixTitle: {
        // fontFamily: "MicrosoftYaHei-Bold",
        fontSize: "14px",
        color: "#131D32",
        lineHeight: "18px",
        fontWeight: "700",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            fontSize: "12px",
        },
    },
    profitText: {
        marginTop: "8px",
        // fontFamily: "DINPro-Bold",
        fontSize: "24px",
        color: "#131D32",
        lineHeight: "28px",
        fontWeight: "700",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            fontSize: "20px",
            lineHeight: "24px",
            marginTop: "4px",
        },
    },
    profitItemLeft: {
        marginRight: "20px",
        [theme.breakpoints.down('sm')]: {
            marginRight: "0",
            marginBottom: "12px",
        },
    },
    homeProject: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginBottom: "20px"
    },
    homeProjectItem: {
        width: "50%",
        paddingLeft: "10px",
        boxSizing: "border-box",
        [theme.breakpoints.down('md')]: {
            width: "100%",
            paddingLeft: "0px",
        },
    },
    homeProjectItemSingle: {
        // marginRight: "20px",
        width: "50%",
        paddingLeft: "0",
        paddingRight: "10px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
            marginRight: "0",
            marginBottom: "16px",
            paddingRight: "0px",
        },
    },
    projectWraper: {
        width: "100%",
        height: "100%",
        padding: "0 40px",
        background: "rgba(255,255,255,0.80)",
        border: "1px solid #FFFFFF",
        borderRadius: "16px",
        boxSizing: "border-box",
        [theme.breakpoints.down('md')]: {
            padding: "0 20px",
        },
    },
    projectHeader: {
        height: "60px",
        lineHeight: "60px",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #EDEFF2",
        [theme.breakpoints.down('sm')]: {
            height: "42px",
            lineHeight: "42px",
        },
    },
    projectTitle: {
        // fontFamily: "MicrosoftYaHei-Bold",
        fontSize: "18px",
        color: "#131D32",
        fontweight: "700",
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
        },
    },
    projectMore: {
        cursor: "pointer",
        "& img": {
            width: "10px",
            marginLeft: "4px",
        },
        [theme.breakpoints.down('sm')]: {
            "& img": {
                display: "none",
            }
        },
    },
    projectMoreText: {
        // fontFamily: "MicrosoftYaHei",
        fontSize: "12px",
        color: "#8391A8",
        fontweight: "400",
    },
    projectContainer: {
        padding: "20px 0 3px",
        [theme.breakpoints.down('sm')]: {
            padding: "16px 0 2px",
        },
    },
    empty: {
        margin: "40px auto 0",
        "&>img": {
            width: "34px",
            display: "block",
            margin: "0 auto 12px",
        },
        "&>p": {
            // fontFamily: "MicrosoftYaHei-Bold",
            fontSize: "14px",
            color: "#8391A8",
            textAlign: "center",
            lineHeight: "22px",
            fontWeight: "700",
        },
        [theme.breakpoints.down('sm')]: {
            margin: "24px auto 40px",
        },
    },
    projectItem: {
        display: "flex",
        marginBottom: "20px",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "16px",
        },
    },
    projectCoin: {
        display: "flex",
        alignItems: "center",
        minWidth: "100px",
    },
    projectCoinImg: {
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        marginRight: "12px",
        [theme.breakpoints.down('sm')]: {
            width: "28px",
            height: "28px",
        },
    },
    projectCoinImgLp: {
        "&:last-of-type": {
            position: "relative",
            left: "-10px"
        },
        marginRight: "0px",
    },
    projectCoinText: {
        fontFamily: "DINPro-Bold",
        fontSize: "20px",
        color: "#131D32",
        fontWeight: "700",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
        },
    },
    projectCoinTextLp: {
        marginLeft: "2px",
    },
    projectApy: {
        fontFamily: "MicrosoftYaHei",
        fontSize: "12px",
        color: "#C5C9D5",
        fontWeight: "400",
        // marginRight: "63px",
        display: "flex",
        alignItems: "center",
        "& span": {
            fontFamily: "DINPro-Bold",
            fontSize: "20px",
            color: "#00B595",
            fontWeight: "700",
            marginLeft: "20px",
            display: "block",
            minWidth: "80px",
            textAlign: "right",
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: "12px",
            "&>i": {
                display: "none",
            },
            "& span": {
                marginLeft: "0",
            },
        },
    },
    projectApySecond: {
        // marginRight: "52px",
        [theme.breakpoints.down('sm')]: {
            marginRight: "10px",
        },
    },
    projectRight: {
        display: "flex",
        alignItems: "center",
    },
    projectLink: {
        padding: "0 14px",
        height: "32px",
        background: whiteColor,
        border: "1px solid",
        borderColor: primaryColor[0],
        borderRadius: "22px",
        fontWeight: "700",
        cursor: "pointer",
        color: primaryColor[0],
        fontSize: "14px",
        minWidth: "96px",
        "&:hover": {
            color: whiteColor,
            backgroundColor: primaryColor[0],
        },
        "& .MuiButton-label": {
            fontSize: "14px",
            fontFamily: "DINPro-Bold",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "0 12px",
            height: "32px",
            backgroundColor: primaryColor[8],
            border: "none",
            borderRadius: "22px",
            color: primaryColor[0],
            fontSize: "12px",
            fontWeight: "700",
            minWidth: "70px",
            "&:hover": {
                color: primaryColor[0],
                backgroundColor: primaryColor[8],
            },
        },
    },
    newWork: {
        width: "100%",
        height: "48px",
        background: "#F6F8FB",
        borderRadius: "24px",
        display: "flex",
        alignItems: "center",
        marginTop: "32px",
        padding:"0 5px",
        boxSizing:"border-box"
    },
    netActived: {
        background: "#FFFFFF",
        "& span": {
            color: primaryColor[0],
        },
        "&.net__item .net__icon-1": {
            background: `url(${require('../../images/icon-BSC.svg')}) no-repeat center center`,
            backgroundSize: "contain",
            
        },
        "&.net__item .net__icon-0": {
            background: `url(${require('../../images/heco.svg')}) no-repeat center center`,
            backgroundSize: "contain",
        }
    },
    netWorkItem: {
        height: "40px",
        borderRadius: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "all 0.2s ease-in",
        cursor: "pointer",
        "& span": {
            fontFamily: "DINPro-Bold",
            fontSize: "18px",
            color: "#131D32",
            fontWeight: "700",
            marginLeft: "8px",
        },
        "&:first-of-type i": {
            width: "26px",
            height: "26px",
            display: "block",
            background: `url(${require('../../images/icon-heco-hui.svg')}) no-repeat center center`,
            backgroundSize: "contain",
        },
        "&:nth-of-type(2) i": {
            width: "26px",
            height: "26px",
            display: "block",
            background: `url(${require('../../images/icon-BSC-hui.svg')}) no-repeat center center`,
            backgroundSize: "contain",
        },
        [theme.breakpoints.down('sm')]: {
            "& span": {
                fontSize: "16px",
                marginLeft: "3.5px",
            },
            "& i": {
                width: "20px",
                height: "20px",
                display: "block",
                backgroundSize: "contain",
            },
            "&:first-of-type i": {
                width: "20px",
                height: "20px",
                backgroundSize: "contain",
            },
            "&:nth-of-type(2) i": {
                width: "20px",
                height: "20px",
                backgroundSize: "contain",
            },
        },
    },
    poolTotalTvl: {
        color: primaryColor[0],
    }
});

export default HomeStyle;
