import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useConnectWallet } from '../../home/redux/hooks';
import {useAggregate} from '../redux/hooks';
// import { useHubChainTvlAndProfit } from '../redux/hooks';
import '../jss/sections/SectionTotal.css'
import BigNumber from "bignumber.js";
// 千分位格式化
function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
            return $1 + ",";
        });
    });
}
const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }
export default memo(({address}) => {
    const { t } = useTranslation();
    const theme = useSelector(state => state.common.theme);
    const aggregateData = useAggregate(theme==='bsc');
    const rateAndTvl = aggregateData[1]/1e18;
    const rateAndReward = aggregateData[0]/1e18;
    const depositedAll = aggregateData[3]/1e18;
    const allEarned = aggregateData[2]/1e18;
    return <ul className={`pool__total-tvl pool__total-tvl-${theme}`}>
        <li>
            <div>{t('Total-lock-in')}($)</div>
            <div>{rateAndTvl  ? formatThousands(forMat(rateAndTvl,2)) : 0}</div>
        </li>
        <li>
            <div>{t('v2-ui-20')}($)</div>
            <div>{rateAndReward  ? formatThousands(forMat(rateAndReward,2)) : 0}</div>
        </li>
        <li>
            <div>{t('v2-ui-19')}($)</div>
            <div>{depositedAll ? formatThousands(forMat(depositedAll,2)) : 0}</div>
        </li>
        <li>
            <div>{t('v2-ui-18')}($)</div>
            <div>{allEarned ? formatThousands(forMat(allEarned,2)) : 0}</div>
        </li>
    </ul>
})
