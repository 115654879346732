import React, { useEffect, useState, useRef, useMemo, memo } from 'react';
import '../jss/sections/ControlPage.css'
// 修改平台分成和池子总额
import ControlProfitAndTotal from './ControlProfitAndTotal';
// 池子管理
import ControlPoolManager from './ControlPoolManager';
// 策略管理
import ControlPolicyManager from './ControlPolicyManager';
// 资金管理
import ControlManyManager from './ControlManyManager';
// 资金管理
import ControlSettingManager from './ControlSettingManager';
export default memo(function ControlPage() {
  // 定义Tabs
  const tabs = useRef([
    {
      name: "运营管理",
      id: 111,
      component:<ControlProfitAndTotal />
    },
    {
      name: "池子管理",
      id: 222,
      component:<ControlPoolManager/>
    },
    {
      name: "策略管理",
      id: 333,
      component:<ControlPolicyManager/>
    },
    {
      name: "资金管理",
      id: 444,
      component:<ControlManyManager/>
    },
    {
      name: "治理",
      id: 555,
      component:<ControlSettingManager/>
    }
  ])
  //当前选中的tab id
  const [selectedTabId, setSelectedTabId] = useState(111);
  return <div>
    {/* tab */}
    <ul className="Control-tab">
      {tabs.current.map(tab => {
        return <li
          key={tab.id}
          onClick={()=>setSelectedTabId(tab.id)}
          className={tab.id === selectedTabId ? 'Control-tab--selected' : ''}>{tab.name}</li>
      })}
    </ul>
    {
      tabs.current.find(tab=>tab.id===selectedTabId).component
    }
  </div>

});