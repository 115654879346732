import React, { useEffect, useState, useRef, useMemo, memo } from 'react';
import '../jss/sections/ControlPolicyManager.css'
import { useSnackbar } from 'notistack';
import { useConnectWallet } from '../../home/redux/hooks';
import { usePoolManager,useGov } from '../redux/hooks';
export default memo(function () {
  const { web3, address } = useConnectWallet()
  const { enqueueSnackbar } = useSnackbar();
  const { editPoolControl, getStrategy, strategyPools, nameArr, unZhiYa, closeAutoZhiYa } = usePoolManager()
  // console.log(nameArr, 'nameArr nameArr ')
  strategyPools.forEach((item, index) => {
    item.name = nameArr[index]
  })
  // console.log(strategyPools, 'strategyPools strategyPools')
  const { govWithdrawLp,govEarnLp ,setPause,allPaused} = useGov()
  // console.log('allPaused---',allPaused)
  // 输入的侧率地址
  const [controlAddress, setControlAddress] = useState('');
  const [openEditControl, setOpenEditControl] = useState(false);
  // 输入策略地址函数
  const controlAddressHandler = (event) => {
    const val = event.target.value;
    setControlAddress(val)
  }
  // 提交编辑的策略地址
  const submitEditControlAddress = (pool) => {
    // 1、点击验证各字段规则
    if (!controlAddress || controlAddress.length !== 42) {
      enqueueSnackbar('请输入正确的Control策略地址！', {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
      return;
    }
    editPoolControl(controlAddress).then((ret) => {
      enqueueSnackbar(`Control策略地址:${ret}`, {
        variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000,
        action: ret ? () => <button onClick={() => window.open(`https://hecoinfo.com/tx/${ret}`, "_blank")}><span style={{ color: "white" }}>查看</span></button> : null
      });
    }).catch(e => {
      enqueueSnackbar(`Control策略地址失败:${JSON.stringify(e)}`, {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
    })
  }

  useEffect(() => {
    if (!web3 || !address) return
    getStrategy()
    const getStrategyTimer = window.setInterval(() => {
      getStrategy()
    }, 5000)
    return () => {
      window.clearInterval(getStrategyTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, address])
  // 解除质押
  function unZhiYaHandler(strategyPool) {
    // console.log('unZhiYaHandler',strategyPool)
    unZhiYa(strategyPool).then((ret) => {
      enqueueSnackbar(`解除质押成功:${ret}`, {
        variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000,
        action: ret ? () => <button onClick={() => window.open(`https://hecoinfo.com/tx/${ret}`, "_blank")}><span style={{ color: "white" }}>查看</span></button> : null
      })
    }).catch((e) => {
      enqueueSnackbar(`解除质押失败:${JSON.stringify(e)}`, {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
    })
  }
  // 关闭自动质押
  function closeAutoZhiYaHandler(strategyPool) {
    // console.log('closeAutoZhiYaHandler',strategyPool)
    closeAutoZhiYa(strategyPool).then((ret) => {
      enqueueSnackbar(`自动质押状态设置成功:${ret}`, {
        variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000,
        action: ret ? () => <button onClick={() => window.open(`https://hecoinfo.com/tx/${ret}`, "_blank")}><span style={{ color: "white" }}>查看</span></button> : null
      })
    }).catch((e) => {
      enqueueSnackbar(`自动质押状态设置失败:${JSON.stringify(e)}`, {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
    })

  }
  return <div className="ControlPolicyManager">
    {/* 开启关闭质押 */}
    <button
      className="ControlPool-button ControlPool-button-list"
      onClick={() => setPause()}
    >
      {`${allPaused?'开启':'关闭'}`}所有质押
    </button>
    {/* 开启关闭质押 end */}
    {/* 添加策略 */}
    <button
      className="ControlPool-button ControlPool-button-list"
      onClick={() => setOpenEditControl(!openEditControl)}
    >
      添加策略
    </button>
    {
      openEditControl ? <div className={"ControlPool-add ControlPool-edit  ControlPool-add--opened"}>
        <h5>策略信息</h5>
        <label>Control策略地址:</label>
        <div>
          <input
            value={controlAddress}
            onChange={controlAddressHandler.bind(this)}
          />
        </div>
        <button
          className="ControlPool-button ControlPool-button-list"
          color="primary"
          onClick={submitEditControlAddress.bind(this)}
        >
          确认
      </button>
      </div> : null
    }
    {/* 添加策略end */}
    <ul className="ControlPolicyManager-coin-list">
      {strategyPools.map((strategyPool, index) => {
        return <li key={index}>
          <div className="ControlPolicyManager-coin-list-top">
            <div className='ControlPolicyManager-coin-list-top-name'>
              币对信息<br />
              {strategyPool.want['00']}/{strategyPool.want['11']}
              <span style={{paddingLeft: '10px', color: "rgb(255, 175, 103)"}}>{strategyPool.name}</span>
            </div>
            <div>
              自动质押状态<br />
              {!strategyPool.paused ? '是' : '否'}
            </div>
          </div>
          <div className="ControlPolicyManager-coin-list-bottom">
            <button
              className="ControlPool-button ControlPool-button-list"
              color="primary"
              onClick={unZhiYaHandler.bind(this, strategyPool)}
            >
              解除质押
            </button>
            <button
              className="ControlPool-button ControlPool-button-list"
              color="primary"
              onClick={closeAutoZhiYaHandler.bind(this, strategyPool)}
            >
              {!strategyPool.paused ? '关闭' : '开启'}自动质押
            </button>
          </div>
        </li>
      })}
    </ul>
  </div>

});