import React, { useEffect, useState } from 'react';
import { useSetAndGetTotals, useFetchPoolBalances, useRateAndTvl, useRateAndTotals,usePoolManager } from '../redux/hooks'
import { useConnectWallet } from '../../home/redux/hooks';
import { useSnackbar } from 'notistack';
import { inputLimitPass } from 'features/helpers/utils';
// import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
export default function ControlPage() {
  const { web3, address } = useConnectWallet();
  const { pools } = useFetchPoolBalances();
  const { enqueueSnackbar } = useSnackbar();
  // const rateAndTvl = useRateAndTvl();

  // 设置池子的总额度
  let { intervalProfitAndTotals } = useSetAndGetTotals();
  // const x5TokenAddress = pools.find(({ id }) => id === 'MDX').tokenAddress;
  // const x5 = rateAndTvl.find(item => item[0].toLowerCase() === x5TokenAddress.toLowerCase());
  // const MDXROTE = x5 ? x5[1] : 0;
  // const matchRote = (address) => {
  //   const matched = rateAndTvl.find(tvl => tvl[0].toLowerCase() === address.toLowerCase()) || [];
  //   return matched[1]
  // }
  const coins = {};
  pools.forEach(pool => {
    coins[pool.token] = ''
  });
  const { sendRates, sendTotals,setMatch100 } = useRateAndTotals();
  const [rates, setRates] = useState(coins)
  const [totals, setTotals] = useState(coins)
  const [loading1, setLoading1] = useState(coins)
  const [loading2, setLoading2] = useState(coins)
  function rateChangeHandler(coinName, event) {
    const v = event.target.value
    if (!inputLimitPass(v, 2) || isNaN(v)) {
      return
    }
    setRates({
      ...rates,
      [coinName]: v
    })
  }
  function totalsChangeHandler(coinName, event) {
    const v = event.target.value
    if (!inputLimitPass(v, 9) || isNaN(v)) {
      return
    }
    setTotals({
      ...totals,
      [coinName]: v
    })
  }
  useEffect(() => {
    if (address && web3) {
      // 获取所有池子的项目总额并设置
      intervalProfitAndTotals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, web3]);

  async function rateSubmit({ token, tokenAddress }) {
    const val = rates[token]
    if (!val || +val <= 0) {
      enqueueSnackbar(`请输入大于0的数字!`, {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
      return
    }
    setLoading1({ ...loading1, [token]: true })
    // const rateChange = await sendRates(val, tokenAddress).catch(e => e);
    await sendRates(val, tokenAddress).then((ret) => {
      enqueueSnackbar(`修改成功:${JSON.stringify(ret)}`, {
        variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 4000,
        action: ret ? () => <button onClick={() => window.open(`https://hecoinfo.com/tx/${ret}`, "_blank")}><span style={{ color: "white" }}>查看</span></button> : null
      });
    }).catch(e => {
      enqueueSnackbar(`修改错误:${JSON.stringify(e)}`, {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
      return e
    });
    setLoading1({ ...loading1, [token]: false })
  }
  async function totalsSubmit({ token, tokenAddress, tokenDecimals }) {
    const val = totals[token]
    if (!val || +val <= 0) {
      enqueueSnackbar(`请输入大于0的数字!`, {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
      return
    }
    setLoading2({ ...loading2, [token]: true })
 
    await sendTotals(val, tokenAddress, tokenDecimals).then((ret) => {
      enqueueSnackbar(`修改成功:${JSON.stringify(ret)}`, {
        variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 4000,
        action: ret ? () => <button onClick={() => window.open(`https://hecoinfo.com/tx/${ret}`, "_blank")}><span style={{ color: "white" }}>查看</span></button> : null
      })
    }).catch(e => {
      enqueueSnackbar(`修改错误:${JSON.stringify(e)}`, {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
      return e
    });
    setLoading2({ ...loading2, [token]: false })
  }
  // 配对顺序
  const {setMatchQueueCall} = usePoolManager()
  const [matchQueue, setMatchQueue] = useState('');
  function setMatchQueueHandler(){
    setMatchQueueCall(matchQueue).then(ret=>{
      enqueueSnackbar(`这只配对顺序成功:${JSON.stringify(ret)}`, {
        variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 4000,
        action: ret ? () => <button onClick={() => window.open(`https://hecoinfo.com/tx/${ret}`, "_blank")}><span style={{ color: "white" }}>查看</span></button> : null
      })
    }).catch(e=>{
      enqueueSnackbar(`设置配对顺序错误:${JSON.stringify(e)}`, {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 4000
      })
    })
  }
  return (
    <>
      <div className="control-wrap control-wrap-queue">

        <h5>配对顺序:</h5>
        <input
          value={matchQueue}
          onChange={(e) => setMatchQueue(e.target.value)}
        />
        <button
          onClick={setMatchQueueHandler.bind(this)}
          disabled={!matchQueue}
        >
          确定
              </button>
      </div>
      {/* <div className="control-wrap control-wrap-queue">

        <Button
          style={{
            width: '100%',
            margin: '12px 5px',
            fontSize: '14px',
            fontWeight: 'bold',
            backgroundImage: 'linear-gradient(90deg, #01A3FE 0%, #006EFF 100%)',
            color: '#FFF',
            borderRadius: "30px",
            boxShadow: '0 2px 2px 0 rgba(53, 56, 72, 0.14), 0 3px 1px -2px rgba(53, 56, 72, 0.2), 0 1px 5px 0 rgba(53, 56, 72, 0.12)',
          }}
          color="primary"
          onClick={setMatch100.bind(this)}
        >
          设置匹配100%
        </Button>
      </div> */}
      <div className="control-wrap">
        <dl className="control-coin-params">
          <dt>平台分成比例:</dt>
          {pools.map(pool =>
            <dd key={pool.tokenAddress}>
              <div className="control-coin-label">
                <span className="control-coin-token">{pool.token}:</span>
                <span className="control-coin-rate">{(10000 - (pool.profit || 5000)) / 100}%</span>
              </div>
              <div className="control-coin-input">
                <span>改:</span>
                <div className="control-input">
                  <input
                    value={rates[pool.token]}
                    onChange={rateChangeHandler.bind(this, pool.token)}
                  />
                </div>
                <span>%</span>
              </div>
              <button
                onClick={rateSubmit.bind(this, pool)}
                disabled={loading1[pool.token]}
              >
                {loading1[pool.token] ? '更改中...' : '更改'}
              </button>
            </dd>
          )}
        </dl>
        <dl className="control-coin-params">
          <dt>项目额度:</dt>
          {pools.map(pool =>
            <dd key={pool.tokenAddress}>
              <div className="control-coin-label">
                <span className="control-coin-token">{pool.token}:</span>
                <span className="control-coin-rate">{pool.total}</span>
              </div>
              <div className="control-coin-input">
                <span>改:</span>
                <div className="control-input control-input-total">
                  <input
                    value={totals[pool.token]}
                    onChange={totalsChangeHandler.bind(this, pool.token)}
                  />
                </div>
              </div>
              <button
                onClick={totalsSubmit.bind(this, pool)}
                disabled={loading2[pool.token]}
              >
                {loading2[pool.token] ? '更改中...' : '更改'}
              </button>
            </dd>
          )}
        </dl>
      </div>
    </>
  );
}