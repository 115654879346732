import React, { useEffect, useState, useRef, useMemo, memo } from 'react';
import '../jss/sections/ControlSettingManager.css'
import { useSnackbar } from 'notistack';
import { useConnectWallet } from '../../home/redux/hooks';
import { useSettingManager } from '../redux/hooks';
import { usePoolManager } from '../redux/hooks';
export default memo(function () {
  const { web3, address } = useConnectWallet()
  const { enqueueSnackbar } = useSnackbar();
  const {
    submitController, setSelectedController, setSelectedGov, submitGov,
    selectedController, originalController, selectedGov, originalGov,
    GovernanceList, addGovernance, removeGovernance, liquidityAddress,
    setLiquidityAddress,rewardAccountValue,setRewardAccount
  } = useSettingManager();
  const { controllerPools, goverPools, getStrategy } = usePoolManager()
  const [inputAddress, setInputAddress] = useState('')
  const [inputLiquidityAddress, setInputLiquidityAddress] = useState('')
  const [inputPingdaiLiquidityAddress, setInputPingdaiLiquidityAddress] = useState('')
  // const [selectedController, setSelectedController] = useState('');
  const [inputController, setInputController] = useState('');
  const [inputGov, setInputGov] = useState('');
  const [openAdd, setOpenAdd] = useState(false)
  const [openAdd1, setOpenAdd1] = useState(false)
  const [openAdd2, setOpenAdd2] = useState(false)
  useEffect(() => {
    if (!web3 || !address) return
    getStrategy()
    console.log(originalController, 'originalController originalController originalController')
    const getStrategyTimer = window.setInterval(() => {
      getStrategy()
    }, 5000)
    return () => {
      window.clearInterval(getStrategyTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, address])
  // console.log(originalController, 'originalController originalController')
  return <div className="ControlSettingManager">
    {/* 修改治理合约 */}
    <h4 className="ControlManyManager-h4">修改治理合约</h4>
    <div className="ControlManyManager-box">
      <p className="ControlManyManager-origin">现有治理合约：{originalGov}</p>
      <div className="ControlManyManager">
        <select value={selectedGov} onChange={(e) => setSelectedGov(e.target.value)}>
          <option value={0}>请选择</option>
          {
            goverPools.map(pool => {
              return <option value={pool.address}>{pool.want['00']}/{pool.want['11']}</option>
            })
          }
        </select>
        <input value={inputGov} onChange={e => setInputGov(e.target.value)} />
        <button onClick={() => submitGov(selectedGov, inputGov)}>确认</button>
      </div>
    </div>
    {/* 设置controller合约 */}
    <h4 className="ControlManyManager-h4">设置controller合约</h4>
    <div className="ControlManyManager-box">
      <p className="ControlManyManager-origin">现有controller：{originalController}</p>
      <div className="ControlManyManager">
        <select value={selectedController} onChange={(e) => setSelectedController(e.target.value)}>
          <option value={0}>请选择</option>
          {
            controllerPools.map(pool => {
              return <option value={pool.address}>{pool.want['00']}/{pool.want['11']}</option>
            })
          }
        </select>
        <input value={inputController} onChange={e => setInputController(e.target.value)} />
        <button onClick={() => submitController(selectedController, inputController)}>确认</button>
      </div>
    </div>

    <button
      className="ControlPool-button"
      color="primary"
      onClick={() => setOpenAdd2(!openAdd2)}
    >
      设置平台分成地址


    </button>

    {/* 新增输入框 */}
    {
      openAdd2 ? <div className={"ControlPool-add" + (openAdd2 ? ' ControlPool-add--opened' : '')}>
        <label>当前平台分成地址:{rewardAccountValue}<br />设置平台分成地址:</label>
        <div>
          <input
            value={inputPingdaiLiquidityAddress}
            onChange={e => setInputPingdaiLiquidityAddress(e.target.value)}
          />
        </div>
        <button
          className="ControlPool-button ControlPool-button-list"
          color="primary"
          onClick={setRewardAccount.bind(this, inputPingdaiLiquidityAddress)}
        >
          确认设置平台分成地址

      </button>
      </div> : null
    }
    <button
      className="ControlPool-button"
      color="primary"
      onClick={() => setOpenAdd1(!openAdd1)}
    >
      设置对冲合约地址

    </button>

    {/* 新增输入框 */}
    {
      openAdd1 ? <div className={"ControlPool-add" + (openAdd1 ? ' ControlPool-add--opened' : '')}>
        <label>当前对冲地址:{liquidityAddress}<br />设置对冲合约地址:</label>
        <div>
          <input
            value={inputLiquidityAddress}
            onChange={e => setInputLiquidityAddress(e.target.value)}
          />
        </div>
        <button
          className="ControlPool-button ControlPool-button-list"
          color="primary"
          onClick={setLiquidityAddress.bind(this, inputLiquidityAddress)}
        >
          确认设置对冲合约地址
      </button>
      </div> : null
    }

    <button
      className="ControlPool-button"
      color="primary"
      onClick={() => setOpenAdd(!openAdd)}
    >
      新增管理员地址
    </button>

    {/* 新增输入框 */}
    {
      openAdd ? <div className={"ControlPool-add" + (openAdd ? ' ControlPool-add--opened' : '')}>
        <label>添加用户地址:</label>
        <div>
          <input
            value={inputAddress}
            onChange={e => setInputAddress(e.target.value)}
          />
        </div>
        <button
          className="ControlPool-button ControlPool-button-list"
          color="primary"
          onClick={addGovernance.bind(this, inputAddress, GovernanceList)}
        >
          确认新增
      </button>
      </div> : null
    }

    <ul className="ControlPool-list">
      {
        GovernanceList.map((item, idx) => <li key={item}>
          <div>管理员地址{++idx}:</div>
          {item}
          <button
            className="ControlPool-button"
            color="primary"
            onClick={removeGovernance.bind(this, item)}
          >
            删除
      </button>
        </li>)
      }
    </ul>
  </div>

});