import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
//  notistack
import { SnackbarProvider } from 'notistack';
//  core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavList from "components/NavList/NavList.js";
import FooterLinks from "components/Footer/FooterLinks.js";
import H5Menu from "components/H5Menu/H5Menu.js";
import { primaryColor } from 'assets/jss/material-kit-pro-react';
import Hidden from "@material-ui/core/Hidden";
import Dialog from 'components/Dialog/Dialog'
//  hooks
import { useConnectWallet, useDisconnectWallet } from './redux/hooks';
//  i18n
import i18next from 'i18next';
//  web3Modal
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { isMobile } from 'features/helpers/utils';
export default function App(props) {
  const { children, location } = props
  const { t, i18n } = useTranslation();
  const { connectWallet, web3, address, networkId, connected, connectWalletPending } = useConnectWallet();
  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null)
  const pageScrollArea = useRef(null)
  const isH5 = isMobile() ? 'h5' : 'pc';
  // window.localStorage.setItem('hahahhaha','hahahhahahah')
  useEffect(() => {
    pageScrollArea.current.scrollTop = 0
    document.body.scrollTop = 0;
  }, [location])
  // 判断是否为h5
  useEffect(() => {
    document.title = isH5 === 'pc' ? t('page-title') : t('page-title-h5');
  }, [i18n, i18n.language, isH5, t])
  // 设置body类名
  useEffect(() => {
    const body = document.querySelector('html');
    body.className = i18n.language;
  }, [i18n.language])
  useEffect(() => {
    const newModal = new Web3Modal({
      network: process.env.NETWORK ? process.env.NETWORK : "mainet",
      cacheProvider: true,
      providerOptions: {
        injected: {
          display: {
            name: "MetaMask",
            description: i18next.t('Home-BrowserWallet')
          },
        }
        ,
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.INFURA_ID
          }
        }
      }
    })
    setModal(newModal)
  }, [setModal])

  useEffect(() => {
    if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
      connectWallet(web3Modal);
    }
  }, [web3Modal, connectWallet])

  const [dialogCtrl, setDialogCtrl] = useState({
    opened: false,
    type: 'warning',
    // "我知道了"
    buttonText: t('v2-ui-1'),
    buttonClickCallback: () => {
      setDialogCtrl({ ...dialogCtrl, opened: false })
    }
  })

  useEffect(() => {
    // 没有metamask时 不走此方法
    if (window.ethereum) {
      // 所有的钱包都有chainId
      let chainId = window.ethereum.chainId;
      // 转换16进制
      if (('' + chainId).includes('x')) {
        chainId = parseInt(chainId, 16);
      }
      if (
        (chainId && !window.localStorage.getItem("NETWORK_ID_USE")) ||
        (chainId && chainId !== Number(window.localStorage.getItem("NETWORK_ID_USE")))
      ) {
        // disconnectWallet(web3, web3Modal)
        window.localStorage.setItem('NETWORK_ID_USE', chainId);
        window.location.reload();
      }
    }
    if (
      web3
      && address
      && !connectWalletPending
      && networkId
      && Boolean(Number(networkId) !== Number(process.env.NETWORK_ID))
      && Boolean(Number(networkId) !== Number(process.env.NETWORK_ID_II))
    ) {
      setDialogCtrl({
        ...dialogCtrl,
        opened: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, address, networkId, connectWalletPending, t])

  return (
    <>
      <Dialog
        type={dialogCtrl.type}
        opened={dialogCtrl.opened}
        buttonText={dialogCtrl.buttonText}
        buttonClickCallback={dialogCtrl.buttonClickCallback}
      >{t('App-SnackBarText')}</Dialog>
      <SnackbarProvider>
        <header>
          <Header
            links={
              <HeaderLinks
                dropdownHoverColor="primary"
                address={address}
                connected={connected}
                connectWallet={() => connectWallet(web3Modal)}
                disconnectWallet={() => disconnectWallet(web3, web3Modal)}
              />
            }
          />
          {/* {children} */}
        </header>
        <main>
          {/* pc */}
          <Hidden smDown implementation="css" className="page__container-box">
            <div className="page__container" style={{
              display: "flex",
              height: "100%",
              backgroundColor: primaryColor[3],
            }}>
              {/* 菜单 */}
              <div className="page__left" style={{
                width: "240px",
                height: "100%",
              }}>
                <NavList></NavList>
              </div>
              {/* 页面 */}
              <div className="page__right" ref={pageScrollArea} style={{
                flex: "1",
                height: "100%",
                padding: "0 10px",
                boxSizing: "border-box",
                overflow: "auto",
              }}>
                <div className="page__right-wrapper">
                  {/* 页面内容 */}
                  {children}
                  {/* footer */}
                  <footer><FooterLinks /></footer>
                </div>
              </div>
            </div>
          </Hidden>
          {/* h5 */}
          <Hidden mdUp implementation="css">
            <div className="page__container" style={{
              width: "100%",
              height: "auto",
              backgroundColor: primaryColor[3],
              paddingBottom: "112px",
            }}>
              {children}
              <FooterLinks />
              <H5Menu></H5Menu>
            </div>
          </Hidden>
        </main>
      </SnackbarProvider>
    </>
  );
}
