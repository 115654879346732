import { ControlPage,VaultPage } from './';

export const VaultRoute =  {
  path: 'vault',
  childRoutes: [
    { path: 'vault', component: VaultPage, isIndex: true },
  ],
};
export const ControlRoute =  {
  path: 'control',
  childRoutes: [
    { path: 'control', component: ControlPage, isIndex: true },
  ],
};
