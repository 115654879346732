import React, { useState, useEffect } from 'react';
import {
  withRouter
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isMobile } from 'features/helpers/utils';
import BigNumber from "bignumber.js";
// import SectionTotalHome from '../vault/sections/SectionTotalHome.js';
import { useConnectWallet } from './redux/hooks';
import { HubPool, ControllerHub, CompoundHub } from 'features/configure/abi';
// import { earnContractAddress as EARN_CONTRACT_ADDRESS } from "../configure/pools";
import {
  useTvlAndProfit,
  useFetchPoolBalances as vaultFetchPoolBalances,
  useAllRate as valutUseAllRate,
} from '../vault/redux/hooks';
import {
  useFetchPoolBalances as lpFetchPoolBalances,
  useAllRate as lpUseAllRate
} from '../lp/redux/hooks';
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/HomeStyle.js";
import "./HomePage.css";
import i18n from 'i18n.js';
const useStyles = makeStyles(styles);

const forMat = (number, precision = 2) => {
  return new BigNumber(
    number
  ).multipliedBy(
    new BigNumber(10000)
  ).dividedToIntegerBy(
    new BigNumber(1)
  ).dividedBy(
    new BigNumber(10000)
  ).toNumber().toFixed(precision)
}
// 千分位格式化
function formatThousands(num) {
  return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
      return $1 + ",";
    });
  });
}
const pic = {
  "zh-heco-pc":require("../../assets/img/banner.png"),
  "en-heco-pc":require("../../assets/img/banner-english.png"),
  "zh-bsc-pc":require("../../assets/img/banner-bsc.png"),
  "en-bsc-pc":require("../../assets/img/banner-english-bsc.png"),
  "zh-heco-h5":require("../../assets/img/banner-h5-heco.png"),
  "en-heco-h5":require("../../assets/img/banner-h5-english-heco.png"),
  "zh-bsc-h5":require("../../assets/img/banner-h5-bsc.png"),
  "en-bsc-h5":require("../../assets/img/banner-h5-english-bsc.png"),
}
// 合作伙伴数据
const partnerList = [
  require("../../images/MDEX.png"),
  require("../../images/logo-DeFiBox.svg"),
  require("../../images/huobi wallet logo.svg"),
  require("../../images/codebank.svg"),
  require("../../images/king_logo.svg"),
  require("../../images/math.svg"),
  require("../../images/TokenPocket_Logo__ traverse.svg"),
  require("../../images/bitkeep.png"),
  require("../../images/HyperPay-Logo.svg"),
  require("../../images/ONTO-black.svg"),
  require("../../images/aolink.svg"),
]
export default withRouter(function HomePage({ history }) {
  const theme = useSelector(state => state.common.theme);
  const { t } = useTranslation();
  const { web3, address } = useConnectWallet();
  const { tvl, profit } = useTvlAndProfit();
  // const current_net = window.localStorage.getItem("NETWORK_ID_USE") && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 56 ? 1 : 0;
  // const [actived, setActived] = useState(current_net)
  const classes = useStyles();
  // 引入 无损池子 和 lp单币挖矿池子
  let { pools: vaultPools } = vaultFetchPoolBalances();
  let { pools: lpPools } = lpFetchPoolBalances();

  // tab栏 数据 纯展示
  const tabList = [
    {
      text: t('Home-Tab1'),
      img: require("../../images/high.svg"),
    },
    {
      text: t('Home-Tab2'),
      img: require("../../images/peizi.svg"),
    },
    {
      text: t('Home-Tab3'),
      img: require("../../images/duichong.svg"),
    },
    {
      text: t('Home-Tab4'),
      img: require("../../images/zijin.svg"),
    },
  ]

  // 合约池子数据
  // const rateAndTvl = useRateAndTvl();
  // const matchRote = (address) => {
  //   const matched = rateAndTvl.find(tvl => tvl[0].toLowerCase() === address.toLowerCase()) || [];
  //   return matched[1]
  // }
  // const x5TokenAddress = pools.find(({ id }) => id === 'MDX').tokenAddress;
  // const x5 = rateAndTvl.find(item => item[0].toLowerCase() === x5TokenAddress.toLowerCase());
  // const MDXROTE = x5 ? x5[1] : 0;

  // 新增 LP 各币种兑MDX汇率
  const vaultCoinRate = valutUseAllRate(false);
  const {poolRate} = lpUseAllRate(true);

  // 无损 单币 年化数据
  const singlePools = vaultPools.filter(pool => pool.topHome)
  const vaultApyMaps = {}
  singlePools.forEach(pool => {
    vaultApyMaps[pool.tokenAddress] = ''
  })
  const [vaultApys, setVaultApys] = useState(vaultApyMaps)
  useEffect(() => {
    let apyIntervalTimer = null;
    async function getSingLePoolsApy() {
      if (!web3) {
        return;
      }
      const poolsApys = {}
      for (let pool of singlePools) {
        const contract = new web3.eth.Contract(HubPool, pool.earnContractAddress);
        const apy = await contract.methods.getApy(pool.tokenAddress).call().catch(0)
        // 回报率
        const rateMdx = vaultCoinRate[pool.tokenAddress] || pool.rateMdx;
        const depositedApy = forMat(apy / (100 * rateMdx))
        // let hubPoolClient = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        // let ctrAddress = await hubPoolClient.methods.controller().call().catch(e => 0)
        let compound = 0;
        let finaCompoundTotalView;
        // let controllerHubClient = new web3.eth.Contract(ControllerHub, ctrAddress);
        // if (!ctrAddress) return;
        // let compoundHubAdress = await controllerHubClient.methods.compoundHub().call().catch(e => 0)
        // if (!compoundHubAdress) {
        //   return;
        // }
        const chainType = window.localStorage.getItem('NETWORK_ID_USE') || '128'
        const  compoundHubAdress = chainType==="128"?'0xcB1CE091C5eB6F223ab31FE027f290B8d1d785E0':"0xBBa1203C6D9ea4daCb78DaE44c6d9f6677a4F240"
        if (compoundHubAdress) {
          const compoundHubClient = new web3.eth.Contract(CompoundHub, compoundHubAdress);
          compound = await compoundHubClient.methods.getApy(pool.tokenAddress).call().catch(0)
        }
        if (compound > 0) {
          const depositedCompound = compound / 100;
          const xCompound = Math.pow((1 + (depositedCompound / 100) / 105120), 105120);
          finaCompoundTotalView = forMat((((depositedApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100);
        } else {
          finaCompoundTotalView = 0;
        }

        poolsApys[pool.tokenAddress] = finaCompoundTotalView
      }
      setVaultApys(poolsApys)
    }
    getSingLePoolsApy();
    apyIntervalTimer = window.setInterval(getSingLePoolsApy, 5000)
    return () => {
      window.clearInterval(apyIntervalTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, address,valutUseAllRate, vaultCoinRate])

  // LP 单币 年化数据
  const lpSinglePools = lpPools.filter(pool => pool.topHome)
  const lpApyMaps = {}
  lpSinglePools.forEach(pool => {
    lpApyMaps[pool.tokenAddress] = ''
  })
  const [lpApys, setLpApys] = useState(lpApyMaps)
  useEffect(() => {
    let apyIntervalTimer = null;
    async function getLpPoolsApy() {
      if (!web3) {
        return;
      }
      const poolsApys = {}
      if (!poolRate) {
        return;
      }
      for (let pool of lpSinglePools) {
        const contract = new web3.eth.Contract(HubPool, pool.earnContractAddress);
        const apy = await contract.methods.getApy(pool.tokenAddress).call().catch(0)
        // 回报率
        const rateMdx = poolRate[pool.tokenAddress];
        const depositedApy = forMat(apy / (100 * rateMdx))
        // console.log(pool.token, '==========');
        // let hubPoolClient = new web3.eth.Contract(HubPool, EARN_CONTRACT_ADDRESS);
        // let ctrAddress = await hubPoolClient.methods.controller().call().catch(e => 0)
        let compound = 0;
        let finaCompoundTotalView;
        // let controllerHubClient = new web3.eth.Contract(ControllerHub, ctrAddress);
        // if (!ctrAddress) return;
        // let compoundHubAdress = await controllerHubClient.methods.compoundHub().call().catch(e => 0)
        // if (!compoundHubAdress) {
        //   return;
        // }
        // console.log(apy, rateMdx, '=========');
        const chainType = window.localStorage.getItem('NETWORK_ID_USE') || '128'
        const  compoundHubAdress = chainType==="128"?'0x660C9CB8f940Df6625B850813f87C018b000bE5E':"0x6BA90A574C7270150B7b3d88E88dF4d4Ab7b89bA"
        if (compoundHubAdress) {
          const compoundHubClient = new web3.eth.Contract(CompoundHub, compoundHubAdress);
          compound = await compoundHubClient.methods.getApy(pool.tokenAddress).call().catch(0)
        }
        if (compound > 0) {
          const depositedCompound = compound / 100;
          const xCompound = Math.pow((1 + (depositedCompound / 100) / 105120), 105120);
          finaCompoundTotalView = forMat((((depositedApy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100);
        } else {
          finaCompoundTotalView = 0;
        }
        poolsApys[pool.tokenAddress] = finaCompoundTotalView
      }
      setLpApys(poolsApys)
    }
    getLpPoolsApy();
    apyIntervalTimer = window.setInterval(getLpPoolsApy, 5000)
    return () => {
      window.clearInterval(apyIntervalTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, address ,poolRate])
  // useEffect(() => {
  //   window.localStorage.getItem("NETWORK_ID_USE") && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 56 ?
  //   setActived(1) : setActived(0)
  // }, [])
  // const netWorkList = [
  //   {
  //     net: "HECO",
  //     icon: "",
  //   },
  //   {
  //     net: "BSC",
  //     icon: "",
  //   },
  // ]
  const isH5 = isMobile()?'h5':'pc';
  const lang = (i18n.language==='zh-CN' || i18n.language==='zh')?'zh':'en'
  return (
    <>
      <div className={`home__container wrapper ${classes.homeContainer}`}>
        {/* 轮播 */}
        <div className={`home__banner ${classes.homeBanner}  home__common-container`}>
          <img src={pic[`${lang}-${theme}-${isH5}`]} alt="" style={{ width: "100%" }} />
        </div>
        {/* Tab 纯展示 无交互 */}
        <div className={`home__Tab home__common-container ${classes.homeTab}`} style={{
          display: "flex",
          alignItems: "center",
        }}>
          {
            tabList.map((item, ind) => {
              return (
                <div className={`tab__item ${classes.tabItem} tab__item${ind}`} key={ind}>
                  <img className={classes.tabImg} src={item.img} alt="" />
                  <p className={classes.tabText}>{item.text}</p>
                </div>
              )
            })
          }
        </div>
        {/* 收益 */}
        <div className={`${classes.homeProfit} home__common-container`}>
          <div className={classes.profit}>
            <div className={`${classes.profitItem} ${classes.profitItemLeft}`}>
              <div className={classes.profitLeftContent}>
                <p className={classes.profitTitle}>{t('Total-lock-in-total')}($)</p>
                <p className={classes.profitText}>{tvl ? formatThousands(forMat(tvl, 2)) : 0}</p>
                {/* <p className={classes.profitText}>{formatThousands(1703111851.26)}</p> */}
              </div>
              {/* <div className={classes.profitRightContent}>
                <img src={require('../../images/profit.svg')} alt="" />
              </div> */}
            </div>
            <div className={classes.profitItem}>
              <div className={classes.profitLeftContent}>
                <p className={classes.profitTitle}>{t('Total-profit')}($)</p>
                <p className={classes.profitText}>{profit ? formatThousands(forMat(profit, 2)) : 0}</p>
                {/* <p className={classes.profitText}>{formatThousands(45202302.67)}</p> */}
              </div>
              {/* <div className={classes.profitRightContent}>
                <img src={require('../../images/profit.svg')} alt="" />
              </div> */}
            </div>
          </div>
          {/* 项目切换 暂时不展示 */}
          {/* <div className={classes.newWork}> */}
            {/* {
              netWorkList.map((item, ind) => {
                return (
                  <div
                    className={`net__item ${classes.netWorkItem} ${ind === actived ? classes.netActived : ''}`}
                    style={{ width: "33.3%" }}
                    key={ind}
                    onClick={() => {
                      setActived(ind);
                    }}
                  >
                    <i className={`net__icon-${ind}`}></i>
                    <span>{item.net}</span>
                  </div>
                )
              })
            } */}
            {/* <div className={classes.netWorkItem} style={{width: "33.3%"}}>
              <img src="" alt=""/>
              <span>BSC</span>
            </div> */}
            {/* <div className={classes.netWorkMore} style={{ width: "33.3%", textAlign: "center", color: "#8391A8" }}>
              <span className="h5__none">{t('Net-More')}</span>
              <span className="pc__none">{t('Net-More1')}</span>
            </div> */}
          {/* </div> */}
          {/* {
            address?<SectionTotalHome
            address={address}
            net={actived}
          />:null
          } */}

          {/* <div className={classes.netProfit}>
            <div className={classes.netProfitContent}>
              <p>{t('Total-lock-in')}($)</p>
              <p>302,123,123,0000</p>
            </div>
            <div className={classes.netProfitContent}>
              <p>{t('Total-profit')}($)</p>
              <p>302,123,123.0000</p>
            </div>
            <div className={classes.netProfitContent}>
              <p>{t('Total-lock-in')}($)</p>
              <p>{t('Total-profit')}($)</p>
            </div>
            <div className={classes.netProfitContent}>
              <p>{t('Total-lock-in')}($)</p>
              <p>{t('Total-profit')}($)</p>
            </div>
          </div> */}
        </div>
        {/* 项目展示 */}
        <div className={classes.homeProject}>
          <div className={`${classes.homeProjectItem} ${classes.homeProjectItemSingle}`}>
            <div className={classes.projectWraper}>
              <div className={classes.projectHeader}>
                <p className={classes.projectTitle}>{t('Home-projectTitle1')}</p>
                <p className={classes.projectMore}>
                  <span className={classes.projectMoreText} onClick={() => {
                    history.push('/vault')
                  }}>{t('notice-more')}</span>
                  <img src={require('../../images/icon-more.svg')} alt="" />
                </p>
              </div>
              <div className={classes.projectContainer}>
                {singlePools.map((pool, index) => {

                  return (
                    <div className={classes.projectItem} key={pool.token}>
                      <div className={classes.projectCoin}>
                        <img className={classes.projectCoinImg} src={require(`../../images/icon2/${pool.token}.svg`)} alt="" />
                        <span className={classes.projectCoinText}>{pool.token}</span>
                      </div>
                      <div className={classes.projectRight}>
                        <div className={`${classes.projectApy} project__apy`}>
                          {/* <i>{t('Home-Project-Text')}</i> */}
                          <span>{!isNaN(vaultApys[pool.tokenAddress])?vaultApys[pool.tokenAddress]:'--'}%</span>
                        </div>
                        <div className={classes.projectButton}>
                          <Button
                            className={classes.projectLink}
                            round
                            type="button"
                            color="primary"
                            onClick={() => {
                              history.push('/vault')
                            }}
                          >
                            {t('Home-Project-Button')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={classes.homeProjectItem}>
            <div className={classes.projectWraper}>
              <div className={classes.projectHeader}>
                <p className={classes.projectTitle}>{t('Home-projectTitle2')}</p>
                <p className={classes.projectMore}>
                  <span className={classes.projectMoreText} onClick={()=>{
                    history.push('/lp')
                  }}>{t('notice-more')}</span>
                  <img src={require('../../images/icon-more.svg')} alt=""/>
                </p>
              </div>
              <div className={classes.projectContainer}>
                {lpSinglePools.map((pool, index) => {

                  return (
                    <div className={classes.projectItem} key={pool.token}>
                      <div className={classes.projectCoin}>
                        <img className={`${classes.projectCoinImg} ${classes.projectCoinImgLp}`} src={require(`../../images/icon2/${pool.tokenOne}.svg`)} alt="" />
                        <img className={`${classes.projectCoinImg} ${classes.projectCoinImgLp}`} src={require(`../../images/icon2/${pool.tokenTwo}.svg`)} alt="" />
                        <span className={`${classes.projectCoinText} ${classes.projectCoinTextLp}`}>{pool.token}</span>
                      </div>
                      <div className={classes.projectRight}>
                        <div className={`home__project-pay ${classes.projectApy} ${classes.projectApySecond} project__apy`}>
                          {/* <i>{t('Home-Project-Text')}</i> */}
                          <span>{!isNaN(lpApys[pool.tokenAddress])?lpApys[pool.tokenAddress]:'--'}%</span>
                        </div>
                        <div className={classes.projectButton}>
                          <Button
                            className={classes.projectLink}
                            round
                            type="button"
                            color="primary"
                            onClick={() => {
                              history.push('/lp')
                            }}
                          >
                            {t('Home-Project-Button')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        {/* <div className={classes.projectContainer}>
              <div className={classes.empty}>
                <img src={require("../../assets/img/empty.svg")} alt="" />
                <p>{t('Empty-Expect')}</p>
              </div>
            </div> */}
        {/* 审计 */}
        <div className={`${classes.homeAudit} home__common-container`} style={{
          width: "100%",
          padding: "32px 80px 48px",
          background: "rgba(255,255,255,0.80)",
          border: "1px solid #FFFFFF",
          borderRadius: "16px",
          boxSizing: "border-box",
        }}>
          <p className={classes.auditTitle}>{t('Home-Audit')}</p>
          <div className={classes.auditImg}>
            <img className={`${classes.auditImgOne} ${classes.auditImgItem}`} src={require("images/logo-lingzonganquan.svg")} alt="" />
            <img className={classes.auditImgItem} src={require("images/logo-chengdulianan.svg")} alt="" />
          </div>
        </div>
        {/* 合作伙伴 */}
        <div className={`home__partner home__common-container ${classes.homePartner}`}>
          <p className={classes.auditTitle}>{t('Home-Partner')}</p>
          <div className={classes.partnerImgContainer}>
            {
              partnerList.map((item, ind) => {
                return (
                  <div className={classes.partnerImgItem} key={ind}>
                    <img src={item} alt="" />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )

})