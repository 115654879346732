export const isBSC = window.localStorage.getItem("NETWORK_ID_USE") && Number(window.localStorage.getItem("NETWORK_ID_USE")) === 56
// 线上
export const earnContractAddress = isBSC ? "0xAdA5598d0E19B4d3C64585b4135c5860d4A0881F" : "0x94ad8542f3F1bBb6D0dFa4B91589a264FF9b0056";
export const USDTAddress = isBSC ? '0x55d398326f99059fF775485246999027B3197955' : '0xa71EdC38d189767582C38A3145b5873052c3e47a';
export const aggContract = isBSC ? '0x390B8F965b8EBf4C33Ae88AAAc8b59052E77Eb96' : '0x9f374825363cc396Ab828793Bc50cE4Db7548943';
export let GOVAddress = "";
export const setGOVAddress = (address) => GOVAddress = address;
export const pools = isBSC ? [
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-USDT',
    name: 'MDX-USDT',
    token: 'MDX-USDT',
    tokenOne: "MDX",
    tokenTwo: "USDT",
    tokenDescription: 'MDX-USDT',
    tokenOneAddress: "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0xe1cBe92b5375ee6AfE1B22b555D257B4357F6C68",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-WBNB',
    name: 'MDX-WBNB',
    token: 'MDX-WBNB',
    tokenOne: "MDX",
    tokenTwo: "WBNB",
    tokenDescription: 'MDX-WBNB',
    tokenOneAddress: "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    tokenTwoAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    tokenAddress: "0xAf9Aa53146C5752BF6068A84B970E9fBB22a87bc",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-BUSD',
    name: 'MDX-BUSD',
    token: 'MDX-BUSD',
    tokenOne: "MDX",
    tokenTwo: "BUSD",
    tokenDescription: 'MDX-BUSD',
    tokenOneAddress: "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    tokenTwoAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    tokenAddress: "0x223740a259E461aBeE12D84A9FFF5Da69Ff071dD",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'BTCB-USDT',
    name: 'BTCB-USDT',
    token: 'BTCB-USDT',
    tokenOne: "BTCB",
    tokenTwo: "USDT",
    tokenDescription: 'BTCB-USDT',
    tokenOneAddress: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0xda28Eb7ABa389C1Ea226A420bCE04Cb565Aafb85",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'DOGE-USDT',
    name: 'DOGE-USDT',
    token: 'DOGE-USDT',
    tokenOne: "DOGE",
    tokenTwo: "USDT",
    tokenDescription: 'DOGE-USDT',
    tokenOneAddress: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0xF8E9b725e0De8a9546916861c2904b0Eb8805b96",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'ETH-USDT',
    name: 'ETH-USDT',
    token: 'ETH-USDT',
    tokenOne: "ETH",
    tokenTwo: "USDT",
    tokenDescription: 'ETH-USDT',
    tokenOneAddress: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    tokenTwoAddress: "0x55d398326f99059fF775485246999027B3197955",
    tokenAddress: "0x0FB881c078434b1C0E4d0B64d8c64d12078b7Ce2",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WBNB-BTCB',
    name: 'WBNB-BTCB',
    token: 'WBNB-BTCB',
    tokenOne: "WBNB",
    tokenTwo: "BTCB",
    tokenDescription: 'WBNB-BTCB',
    tokenOneAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    tokenTwoAddress: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    tokenAddress: "0x969f2556F786a576F32AeF6c1D6618f0221Ec70e",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WBNB-ETH',
    name: 'WBNB-ETH',
    token: 'WBNB-ETH',
    tokenOne: "WBNB",
    tokenTwo: "ETH",
    tokenDescription: 'WBNB-ETH',
    tokenOneAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    tokenTwoAddress: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    tokenAddress: "0x82E8F9e7624fA038DfF4a39960F5197A43fa76aa",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'ETH-BTCB',
    name: 'ETH-BTCB',
    token: 'ETH-BTCB',
    tokenOne: "ETH",
    tokenTwo: "BTCB",
    tokenDescription: 'ETH-BTCB',
    tokenOneAddress: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    tokenTwoAddress: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    tokenAddress: "0x577d005912C49B1679B4c21E334FdB650E92C077",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'BTCB-BUSD',
    name: 'BTCB-BUSD',
    token: 'BTCB-BUSD',
    tokenOne: "BTCB",
    tokenTwo: "BUSD",
    tokenDescription: 'BTCB-BUSD',
    tokenOneAddress: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    tokenTwoAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    tokenAddress: "0x4fb8253432FB3e92109c91E3Ff2b85FfA0f6A1F4",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
] : [
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-USDT',
    name: 'MDX-USDT',
    token: 'MDX-USDT',
    tokenOne: "MDX",
    tokenTwo: "USDT",
    tokenDescription: 'MDX-USDT',
    tokenOneAddress: "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
    tokenTwoAddress: "0xa71edc38d189767582c38a3145b5873052c3e47a",
    tokenAddress: "0x615E6285c5944540fd8bd921c9c8c56739Fd1E13",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HFIL-USDT',
    name: 'HFIL-USDT',
    token: 'HFIL-USDT',
    tokenOne: "HFIL",
    tokenTwo: "USDT",
    tokenDescription: 'HFIL-USDT',
    tokenOneAddress: "0xae3a768f9ab104c69a7cd6041fe16ffa235d1810",
    tokenTwoAddress: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
    tokenAddress: "0x600072aF0470d9Ed1D83885D03d17368943fF22A",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX-WHT',
    name: 'MDX-WHT',
    token: 'MDX-WHT',
    tokenOne: "MDX",
    tokenTwo: "WHT",
    tokenDescription: 'MDX-WHT',
    tokenOneAddress: "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
    tokenTwoAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    tokenAddress: "0x6Dd2993B50b365c707718b0807fC4e344c072eC2",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
    // 是否上首页
    topHome: true,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HBTC-ETH',
    name: 'HBTC-ETH',
    token: 'HBTC-ETH',
    tokenOne: "HBTC",
    tokenTwo: "ETH",
    tokenDescription: 'HBTC-ETH',
    tokenOneAddress: "0x66a79d23e58475d2738179ca52cd0b41d73f0bea",
    tokenTwoAddress: "0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
    tokenAddress: "0x793c2a814e23EE38aB46412Be65E94Fe47D4B397",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WHT-HUSD',
    name: 'WHT-HUSD',
    token: 'WHT-HUSD',
    tokenOne: "WHT",
    tokenTwo: "HUSD",
    tokenDescription: 'WHT-HUSD',
    tokenOneAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    tokenTwoAddress: "0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047",
    tokenAddress: "0x3375afF2CAcF683b8FC34807B9443EB32e7Afff6",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HBTC-USDT',
    name: 'HBTC-USDT',
    token: 'HBTC-USDT',
    tokenOne: "HBTC",
    tokenTwo: "USDT",
    tokenDescription: 'HBTC-USDT',
    tokenOneAddress: "0x66a79d23e58475d2738179ca52cd0b41d73f0bea",
    tokenTwoAddress: "0xa71edc38d189767582c38a3145b5873052c3e47a",
    tokenAddress: "0xFBe7b74623e4be82279027a286fa3A5b5280F77c",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'ETH-USDT',
    name: 'ETH-USDT',
    token: 'ETH-USDT',
    tokenOne: "ETH",
    tokenTwo: "USDT",
    tokenDescription: 'ETH-USDT',
    tokenOneAddress: "0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
    tokenTwoAddress: "0xa71edc38d189767582c38a3145b5873052c3e47a",
    tokenAddress: "0x78C90d3f8A64474982417cDB490E840c01E516D4",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WHT-ETH',
    name: 'WHT-ETH',
    token: 'WHT-ETH',
    tokenOne: "WHT",
    tokenTwo: "ETH",
    tokenDescription: 'WHT-ETH',
    tokenOneAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    tokenTwoAddress: "0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
    tokenAddress: "0x53E458aD1CFEB9582736db6BdE9aF89948e3bc3d",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'HBTC-WHT',
    name: 'HBTC-WHT',
    token: 'HBTC-WHT',
    tokenOne: "HBTC",
    tokenTwo: "WHT",
    tokenDescription: 'HBTC-WHT',
    tokenOneAddress: "0x66a79d23e58475d2738179ca52cd0b41d73f0bea",
    tokenTwoAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    tokenAddress: "0xBFff969A85e355eE0851b019Dba1e87C7780F40d",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
  {
    // 标识新的，表示开放额度
    // type: '',
    // 开放额度
    openAmount: "3,000,000",
    // 开放时间
    openDate: "2021.04.26(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WHT-USDT',
    name: 'WHT-USDT',
    token: 'WHT-USDT',
    tokenOne: "WHT",
    tokenTwo: "USDT",
    tokenDescription: 'WHT-USDT',
    tokenOneAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    tokenTwoAddress: "0xa71edc38d189767582c38a3145b5873052c3e47a",
    tokenAddress: "0x499B6E03749B4bAF95F9E70EeD5355b138EA6C31",
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 11000000,
  },
]
