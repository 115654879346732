/* eslint-disable */
import React, { useState, useEffect, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from "bignumber.js";
import { byDecimals } from 'features/helpers/bignumber';
import { isMobile } from 'features/helpers/utils';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Button from '../../../components/Button/Button'
import Dialog from '../../../components/Dialog/Dialog'
//  hooks
import { useConnectWallet } from '../../home/redux/hooks';
import '../jss/sections/SectionPools.css';
import {
  useSetAndGetTotals,
  useFetchBalances,
  useFetchPoolBalances,
  useFetchApproval,
  useFetchDeposit,
  useFetchWithdraw,
  useEarned,
  useApy,
  useCompound,
  useDeposited,
  useRateAndTvl,
  useAllRate,
} from '../redux/hooks';

// import SectionTitle from "./SectionTitle";
import SectionQuestion from "./SectionQuestion";
import SectionTotal from "./SectionTotal";
import SectionTitle from "./SectionTitle";
import SectionFilter from "./SectionFilter";
import SectionCountDown from "./SectionCountDown";
import { inputLimitPass, inputFinalVal, isEmpty } from 'features/helpers/utils';

export default memo(function SectionPools() {
  // console.log(123123);
  const { t, i18n } = useTranslation();
  const theme = useSelector(state => state.common.theme);
  const { web3, address, networkId, connected, connectWallet, connectWalletPending } = useConnectWallet();
  function connectHandler() {
    const newModal = new Web3Modal({
      network: process.env.NETWORK ? process.env.NETWORK : "mainet",
      cacheProvider: true,
      providerOptions: {
        injected: {
          display: {
            name: "MetaMask",
            description: t('Home-BrowserWallet')
          },
        }
        ,
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.INFURA_ID
          }
        }
      }
    })
    connectWallet(newModal);
  }
  let { pools, fetchPoolBalances } = useFetchPoolBalances();
  // console.log(pools, '=================');
  // 设置池子的总额度
  const { getPoolTotal } = useSetAndGetTotals();
  const { tokens, fetchBalances } = useFetchBalances();
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchDeposit, fetchDepositEth, fetchDepositPending } = useFetchDeposit();
  const { fetchWithdraw, fetchWithdrawEth, fetchWithdrawPending, fetchWithdrawIncomePending } = useFetchWithdraw();
  const rateAndTvl = useRateAndTvl();
  const [depositedBalance, setDepositedBalance] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState({});

  // 新增 各币种兑MDX汇率
  const coinRate = useAllRate(false);
  // console.log(coinRate, '=====================');
  // const x5TokenAddress = pools.find(({ id }) => id === 'MDX').tokenAddress;
  // const x5 = rateAndTvl.find(item => item[0].toLowerCase() === x5TokenAddress.toLowerCase());
  // const MDXROTE = x5 ? x5[1] : 0;
  
  const dispatch = useDispatch();
  // 打开对话框
  const openDialogHandler = (type, index) => {
    pools[index].dialog.opened = true;
    if (type === 'widthdraw') {
      pools[index].dialog.widthdrawOpened = true
    }
    if (type === 'deposit') {
      pools[index].dialog.depositOpened = true
    }
    dispatch({ type: 'OPEN_POOL_DIALOG', data: pools });
  }

  // 关闭对话框
  const closeDialogHandler = (type, index) => {
    pools[index].dialog.opened = false;
    if (type === 'widthdraw') {
      pools[index].dialog.widthdrawOpened = false
    }
    if (type === 'deposit') {
      pools[index].dialog.depositOpened = false
    }
    dispatch({ type: 'OPEN_POOL_DIALOG', data: pools });
  }
  // 所有状态的对话操作
  const [dialogCtrl, setDialogCtrl] = useState({
    opened: false,
    type: 'success',
    // "我知道了"
    buttonText: t('v2-ui-1'),
    // 授权成功
    contentText: t('v2-ui-2'),
    buttonClickCallback: () => {
      setDialogCtrl({ ...dialogCtrl, opened: false })
    }
  })
  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
    total = Number(total)
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    if (isNaN(Number(value))) {
      value = ''
    }
    let sliderNum = 0;
    let inputVal = Number(value.replace(',', ''));
    if (value) {
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }
    switch (type) {
      case 'depositedBalance':
        setDepositedBalance({
          ...depositedBalance,
          [index]: inputFinalVal(value, total, tokenDecimals),
          [`slider-${index}`]: sliderNum,
        });
        break;
      case 'withdrawAmount':
        setWithdrawAmount({
          ...withdrawAmount,
          [index]: inputFinalVal(value, total, tokenDecimals),
          [`slider-${index}`]: sliderNum,
        });
        break;
      default:
        break;
    }
  }
  const onApproval = (pool, index, event) => {
    event.stopPropagation();
    fetchApproval({
      address,
      web3,
      tokenAddress: pool.tokenAddress,
      contractAddress: pool.earnContractAddress,
      index
    }).then(
      () => {
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: t("Approval-success"),
          // 确认、存入
          cancelButtonText: t('Farm-Dialog-Confirm'),
          buttonText: t('Vault-DepositButton'),
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
            openDialogHandler('deposit', index);
          },
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
        })
      }
    ).catch(
      error => {
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'warning',
          contentText: t("Approval-error"),
          // 重试
          buttonText: t('v2-ui-3')
        })
      }
    )
  }

  const onDeposit = (pool, index, isAll, balanceSingle, event) => {
    event.stopPropagation();
    if (isAll) {
      setDepositedBalance({
        ...depositedBalance,
        [index]: balanceSingle.toString(10),
        [`slider-${index}`]: 100,
      })
    }
    let amountValue = depositedBalance[index] ? depositedBalance[index].replace(',', '') : depositedBalance[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    if (depositedBalance[`slider-${index}`] === 100) {
      isAll = true;
    }


    fetchDeposit({
      address,
      web3,
      isAll,
      amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
      contractAddress: pool.earnContractAddress,
      contractToken: pool.tokenAddress,
      index
    }).then(
      ({ transactionHash }) => {
        setDepositedBalance({ ...depositedBalance, [index]: '', [`slider-${index}`]: 0 })
        const networkId = window.localStorage.getItem('NETWORK_ID_USE');
        const tx = {
          "56": `https://bscscan.com/tx/${transactionHash}`,
          "128": `https://hecoinfo.com/tx/${transactionHash}`
        }
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: t("Deposit-success"),
          // 查看我的交易
          buttonText: <a href={tx[networkId]} target="_blank">{t("v2-ui-4")}</a>,
          cancelButtonText: t("v2-ui-1"),
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
          }
        })
      }
    ).catch(
      error => {
        {
          setDepositedBalance({ ...depositedBalance, [index]: '', [`slider-${index}`]: 0 })
          setDialogCtrl({
            ...dialogCtrl,
            opened: true,
            type: 'warning',
            contentText: t("Deposit-error"),
            buttonText: t("v2-ui-3")
          })
        }
      }
    )

  }

  const onWithdraw = (pool, index, isAll, singleDepositedBalance, isWithdrawIncome, event) => {

    event.stopPropagation();
    if (isAll) {
      setWithdrawAmount({
        ...withdrawAmount,
        [index]: String(forMat(singleDepositedBalance, 8)),
        [`slider-${index}`]: 100,
      })
    }
    let amountValue = withdrawAmount[index] ? withdrawAmount[index].replace(',', '') : withdrawAmount[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    if (withdrawAmount[`slider-${index}`] === 100) {
      isAll = true;
    }


    fetchWithdraw({
      address,
      web3,
      isAll,
      amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
      contractAddress: pool.earnContractAddress,
      contractToken: pool.tokenAddress,
      index,
      isWithdrawIncome
    }).then(
      ({ transactionHash }) => {
        setWithdrawAmount({ ...withdrawAmount, [index]: '', [`slider-${index}`]: 0 })
        const networkId = window.localStorage.getItem('NETWORK_ID_USE');
        const tx = {
          "56": `https://bscscan.com/tx/${transactionHash}`,
          "128": `https://hecoinfo.com/tx/${transactionHash}`
        }
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'success',
          contentText: isWithdrawIncome ? t("v2-ui-7") : t("v2-ui-5"),
          buttonText: <a href={tx[networkId]} target="_blank">{t("v2-ui-4")}</a>,
          cancelButtonText: t("v2-ui-1"),
          cancelButtonCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false })
          },
          buttonClickCallback: () => {
            setDialogCtrl({ ...dialogCtrl, opened: false });
            // const openerWindow = isMobile() ? window : window.open('_blank');
            // const networkId = window.localStorage.getItem('NETWORK_ID_USE');
            // const tx = {
            //   "56": `https://bscscan.com/tx/${transactionHash}`,
            //   "128": `https://hecoinfo.com/tx/${transactionHash}`
            // }
            // openerWindow.location.href = tx[networkId];
          }
        })
      }
    ).catch(
      error => {
        setWithdrawAmount({ ...withdrawAmount, [index]: '', [`slider-${index}`]: 0 })
        setDialogCtrl({
          ...dialogCtrl,
          opened: true,
          type: 'warning',
          contentText: isWithdrawIncome ? t("v2-ui-8") : t("v2-ui-6"),
          buttonText: t("v2-ui-3")
        })
      }
    )


  }

  useEffect(() => {
    if (address && web3) {
      fetchBalances({ address, web3, tokens });
      fetchPoolBalances({ address, web3, pools });
      // 获取所有池子的项目总额并设置
      getPoolTotal()
      const id = setInterval(() => {
        fetchBalances({ address, web3, tokens });
        fetchPoolBalances({ address, web3, pools });
        // 获取所有池子的项目总额并设置
        getPoolTotal()
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, web3, fetchBalances, fetchPoolBalances]);

  const isMoreDepostLimit = (value, depostLimit) => {
    if (isEmpty(value) || depostLimit == 0 || value < depostLimit) {
      return false
    }
    return true;
  }
  const matchTvl = (address) => {
    const matched = rateAndTvl.find(tvl => tvl[0].toLowerCase() === address.toLowerCase());
    if (!matched) {
      return (0).toFixed(2)
    }
    return (matched[2] / Number(`1e${matched[3]}`)).toFixed(2)
  }
  // const matchRote = (address) => {
  //   const matched = rateAndTvl.find(tvl => tvl[0].toLowerCase() === address.toLowerCase()) || [];
  //   return matched[1]
  // }
  const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }
  // 千分位格式化
  function formatThousands(num) {
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
        return $1 + ",";
      });
    });
  }
  // 过滤参与打项目
  const [isPartake, setIsPartake] = useState(false);
  // 所有的项目
  const poolsMap = pools.map((pool, index) => {
    // 余额
    const balanceSingle = byDecimals(tokens[pool.token].tokenBalance, pool.tokenDecimals);
    // 已存入
    let { deposited: deposite } = useDeposited(pool.earnContractAddress, pool.tokenAddress, pool.tokenDecimals);
    deposite = byDecimals(deposite, pool.tokenDecimals).toNumber();
    // 已获益
    const earned = useEarned(pool.earnContractAddress, pool.tokenAddress);
    //console.log("earned: ", earned);
    // 回报率
    // const rateMdx = matchRote(pool.tokenAddress) / MDXROTE || pool.rateMdx;
    const rateMdx = coinRate[pool.tokenAddress];
    const apy = (useApy(pool.earnContractAddress, pool.tokenAddress, pool, rateMdx) / (100 * rateMdx)) / (10**(18-pool.tokenDecimals));
    const depositedApy = forMat(apy);
    // 复利 R1/R2*((1+R2/m)^m-1)
    let { compound, compoundEarned } = useCompound(pool.tokenAddress)
    let finaCompound;
    let finaCompoundTotal;
    let finaCompoundTotalView;
    // 复利存在 取复利 复利不存在 取单利
    // 复利新增 =  复利 - 单利
    // console.log(rateMdx, pool.token, '-------');
    if (compound > 0 && rateMdx) {
      const depositedCompound = compound / 100;
      const xCompound = Math.pow((1 + (depositedCompound / 100) / 105120), 105120);
      finaCompound = forMat(((((apy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100) - apy);
      finaCompoundTotal = forMat((((apy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100);
      finaCompoundTotalView = forMat((((apy / 100) / (depositedCompound / 100)) * (xCompound - 1)) * 100);
    } else {
      finaCompound = undefined;
      finaCompoundTotalView = undefined;
      finaCompoundTotal = depositedApy;
    }
    // 复利未提收益  汇总
    const finaCompoundEarned = forMat(earned * 1 + compoundEarned * 1);
    // 过滤已参与
    if (isPartake && !Number(finaCompoundEarned)) {
      return null;
    }
    return (
      <div className="pools__item" key={index}>
        {/* <div className={`pools__item${isPartake&&!Number(finaCompoundEarned)?' is-hide':''}`} key={index}> */}
        <div className="pools__box">
          <ul className="pools__rows">
            {/* 币种名称+ICON */}
            <li className="pools__row-1">
              <div className="pools__logo-name">
                <img
                  className="pools__coin-logo"
                  alt={pool.token}
                  src={require(`../../../images/icon2/${pool.token}.svg`)}
                />
                <div className="pools__coin-name">
                  {pool.token}
                </div>
              </div>
              <div className="pools__info">
                {t('Vault-Tips-3')}{i18n.language === 'zh' || i18n.language === 'zh-CN' ? pool.token : ''}{t('Vault-Tips-4')}
                <SectionQuestion value={t('Vault-Tips1')} />
              </div>
            </li>
            {/* 年化收益率 */}
            <li className="pools__row pools__apy">
              <div className="pools__labe-field">
                {t('Vault-ListAPY')}<span className={`color-blue ${theme}-color-blue`}>{t('Vault-ListAPY1')}</span>
                <SectionQuestion value={
                  <span>
                    <span className="question__item">{t('APR')}<strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(depositedApy) ? '0.00' : depositedApy}%</strong></span>
                    <span className="question__item">{t('APY')}<strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(finaCompound) ? '0.00' : finaCompound}%</strong></span>
                    <span className="question__item">{t('APYTOTAL')}<strong style={{ color: "rgb(255, 175, 103)" }}>{isNaN(finaCompoundTotal) ? '0.00' : finaCompoundTotal}%</strong></span>
                    <span className="question__item-text" style={{ display: 'block', marginTop: '10px' }}>{t('Apr-Tips1')}</span>
                    <span className="question__item-text">{t('Apr-Tips2')}</span>
                  </span>
                } />
              </div>
              <div className="pools__apy-value">
                {isNaN(finaCompoundTotalView) ? '0.00' : finaCompoundTotalView}%
              </div>
            </li>
            {/* 未提收益 */}
            <li className="pools__row">
              <div className="pools__labe-field">{t('v2-ui-9')}(MDX)<SectionQuestion value={t('Vault-TipsEarned')} /></div>
              <div className="pools__label-value pools__label-value--black">{String((finaCompoundEarned / 1e18).toFixed(5)).slice(0, -1)}</div>
            </li>
            {/* 锁仓量 */}
            <li className="pools__row">
              <div className="pools__labe-field">{t('Lock-volume')} ({pool.token})</div>
              <div className="pools__label-value">{formatThousands(matchTvl(pool.tokenAddress))}</div>
            </li>
            {/* 剩余可投 */}
            <li className="pools__row">
              <div className="pools__labe-field">{t('Remaining-investment')} ({pool.token})<SectionQuestion value={t('Vault-Tips2')} /></div>
              <div className="pools__label-value">{(pool.total - matchTvl(pool.tokenAddress)).toFixed(2) <= 0 ? (0).toFixed(2) : (pool.total - matchTvl(pool.tokenAddress)).toFixed(2)}</div>
            </li>
            {/* 百分比 */}
            <li className="pools__row-rate">
              <div className="pools__rate">
                <span className={`${theme}-pools_rate`} style={{ width: `${(matchTvl(pool.tokenAddress) / pool.total).toFixed(2) * 100}%` }}></span>
              </div>
              <div className="pools__rate-value">
                {(matchTvl(pool.tokenAddress) / pool.total).toFixed(2) * 100 > 100 ? 100 : (pool.total == 0 ? 0 : String((matchTvl(pool.tokenAddress) / pool.total * 100)).split('.')[0])}%
              </div>
            </li>
            {/* 授权 提取 存入按钮 */}
            <li className="pools__group-buttons">
              {connected ? (pool.allowance === 0 ?
                // 未授权 展示 提取 和 授权
                <div className="pools__button-group">
                  <Button
                    className="pools__dialog__option"
                    type={2}
                    loading={pool.dialog.widthdrawOpened}
                    onClick={() => openDialogHandler('widthdraw', index)}
                  >
                    {pool.dialog.widthdrawOpened ? '' : t('v2-ui-10')}
                  </Button>
                  <Button
                    className="pools__dialog__option"
                    loading={fetchApprovalPending[index]}
                    disabled={fetchApprovalPending[index]}
                    onClick={onApproval.bind(this, pool, index)}
                  >
                    {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                  </Button>
                </div> :
                // <Button loading={fetchApprovalPending[index]} onClick={onApproval.bind(this, pool, index)} disabled={fetchApprovalPending[index]}>
                //   {fetchApprovalPending[index] ? '' : t('Vault-ApproveButton')}
                // </Button> :
                // 已授权 展示 提取 和 存入
                <div className="pools__button-group">
                  <Button
                    className="pools__dialog__option"
                    type={2}
                    loading={pool.dialog.widthdrawOpened}
                    onClick={() => openDialogHandler('widthdraw', index)}
                  >
                    {pool.dialog.widthdrawOpened ? '' : t('v2-ui-10')}
                  </Button>
                  <Button
                    className="pools__dialog__option"
                    loading={pool.dialog.depositOpened}
                    onClick={() => openDialogHandler('deposit', index)}
                  >
                    {pool.dialog.depositOpened ? '' : t("Vault-DepositButton")}
                  </Button>
                </div>) :
                <Button className="pools__dialog__option" loading={connectWalletPending} onClick={connectHandler} disabled={connectWalletPending}>
                  {connectWalletPending ? '' : t("v2-ui-17")}
                </Button>
              }
            </li>
          </ul>
          {/* 毛玻璃icon */}
          <div className="pools__mao-logo__wrap">
            <img
              className="pools__mao-logo"
              alt={pool.token}
              src={require(`../../../images/icon2/${pool.token}.svg`)}
            />
          </div>
        </div>

        {/* 弹出框内容 */}
        {pool.dialog.opened ? <div className='pools__dialog'>
          {/* 存入相关 */}
          {
            pool.dialog.depositOpened ? <div className="pools__dialog-inner pools__dialog-deposite">
              <div className="pools__dialog__header">
                {t("Vault-DepositButton")}
                <span className="pools__dialog__header__close" onClick={() => closeDialogHandler('deposit', index)}></span>
              </div>
              <ul className="pools__dialog__fields">
                {/* logo coinname info */}
                <li className="pools__row-1">
                  <div className="pools__logo-name">
                    <img
                      className="pools__coin-logo"
                      alt={pool.token}
                      src={require(`../../../images/icon2/${pool.token}.svg`)}
                    />
                    <div className="pools__coin-name">
                      {pool.token}
                      {/* 存在标签时展示问号悬浮 否则 不展示 */}
                      {/* {pool.type ? <SectionQuestion value={<span className="SectionQuestion-custom-tips">
                    <time>{pool.openDate}</time>
                    <span>{t('Vault-View-9')}<strong>{pool.openAmount}</strong>{t('Vault-View-10')}</span>
                  </span>} label={pool.type == 'NEW' ? "NEW" : t('Vault-View-11')} type={pool.type ? 1 : 0} /> : ''} */}
                    </div>
                  </div>
                  <div className="pools__info">
                    {t('Vault-Tips-3')}{i18n.language === 'zh' || i18n.language === 'zh-CN' ? pool.token : ''}{t('Vault-Tips-4')}
                    {/* <SectionQuestion value={t('Vault-Tips1')} /> */}
                  </div>
                </li>
                {/* 已存入 */}
                <li className="pools__row">
                  <div className="pools__labe-field">
                    {t('Vault-ListDeposited')}({pool.token})
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {String(deposite.toFixed(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 剩余可存 */}
                <li className="pools__row">
                  <div className="pools__labe-field">
                    {t('Vault-SYDeposited')}({pool.token})
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {(pool.total - matchTvl(pool.tokenAddress)).toFixed(2) <= 0 ? (0).toFixed(2) : (pool.total - matchTvl(pool.tokenAddress)).toFixed(2)}
                  </div>
                </li>
                {/* 余额 */}
                <li className="pools__row">
                  <div className="pools__labe-field">
                    {t('Vault-Balance')}({pool.token})
                  </div>
                  <div className="pools__label-value pools__label-value--black">
                    {String(balanceSingle.toFormat(5)).slice(0, -1)}
                  </div>
                </li>
                {/* 输入框 */}
                <li className="pools__dialog__input">
                  <input
                    placeholder={t('v2-ui-11')}
                    value={depositedBalance[index] != undefined ? depositedBalance[index] : ''}
                    onChange={changeDetailInputValue.bind(this, 'depositedBalance', index, balanceSingle.toNumber(), pool.tokenDecimals)}
                  />
                  {/* 最大 全部存入按钮 */}
                  <Button
                    className="pools__dialog__deposit-all"
                    onClick={onDeposit.bind(this, pool, index, true, balanceSingle)}
                    loading={fetchDepositPending[index]}
                    type={2}
                    disabled={
                      !balanceSingle.toNumber() || fetchDepositPending[index] || (isMoreDepostLimit(balanceSingle.toNumber(), pool.depostLimit))
                    }>
                    {fetchDepositPending[index] ? '' : t('v2-ui-12')}
                  </Button>
                </li>
                {/* 存入按钮 */}
                <li>
                  <Button
                    className="pools__dialog__option"
                    onClick={onDeposit.bind(this, pool, index, false, balanceSingle)}
                    loading={fetchDepositPending[index]}
                    disabled={
                      !Number(String((depositedBalance[index])).replace(',', '')) || fetchDepositPending[index] || isMoreDepostLimit(new BigNumber(depositedBalance[index]).toNumber(), pool.depostLimit)
                    }>{fetchDepositPending[index] ? '' : t("Vault-DepositButton")}</Button>
                </li>
              </ul>
            </div> : null
          }
          {/* 提取相关 */}
          {
            pool.dialog.widthdrawOpened ? <div className="pools__dialog-inner pools__dialog-withdraw">
              <div className="pools__dialog__header">
                {t('v2-ui-10')}
                <span className="pools__dialog__header__close" onClick={() => closeDialogHandler('widthdraw', index)}></span>
              </div>
              <ul className="pools__rows">
                {/* 币种名称+ICON */}
                <li className="pools__row-1">
                  <div className="pools__logo-name">
                    <img
                      className="pools__coin-logo"
                      alt={pool.token}
                      src={require(`../../../images/icon2/${pool.token}.svg`)}
                    />
                    <div className="pools__coin-name">
                      {pool.token}
                      {/* 存在标签时展示问号悬浮 否则 不展示 */}
                      {/* {pool.type ? <SectionQuestion value={<span className="SectionQuestion-custom-tips">
                    <time>{pool.openDate}</time>
                    <span>{t('Vault-View-9')}<strong>{pool.openAmount}</strong>{t('Vault-View-10')}</span>
                  </span>} label={pool.type == 'NEW' ? "NEW" : t('Vault-View-11')} type={pool.type ? 1 : 0} /> : ''} */}
                    </div>
                  </div>
                  <div className="pools__info">
                    {t('Vault-Tips-3')}{i18n.language === 'zh' || i18n.language === 'zh-CN' ? pool.token : ''}{t('Vault-Tips-4')}
                    {/* <SectionQuestion value={t('Vault-Tips1')} /> */}
                  </div>
                </li>
                {/* 提取收益 */}
                <li className="pools__income-field">
                  <div className="pools__income-label">
                    <p>{t('Vault-WithdrawCan')}({pool.earnedToken})</p>
                    <p>{String((finaCompoundEarned / 1e18).toFixed(5)).slice(0, -1)}</p>
                  </div>
                  <div className="pools__income-button">
                    <Button
                      loading={fetchWithdrawIncomePending[index]}
                      disabled={fetchWithdrawIncomePending[index] || !Number(forMat(finaCompoundEarned / 1e18, 8))}
                      onClick={onWithdraw.bind(this, pool, index, false, deposite, true)}
                    >{fetchWithdrawIncomePending[index] ? '' : t("Vault-WithdrawIncome")}</Button>
                  </div>
                </li>
                {/* 可提取本金 */}
                <li className="pools__dialog__withdraw-field">
                  <span>{t("v2-ui-14")}({pool.token})</span>
                  <span>{String(deposite.toFixed(5)).slice(0, -1)}</span>
                </li>
                {/* 输入框 */}
                <li className="pools__dialog__input">
                  <input
                    placeholder={t('v2-ui-11')}
                    value={withdrawAmount[index] != undefined ? withdrawAmount[index] : ''}
                    onChange={changeDetailInputValue.bind(this, 'withdrawAmount', index, deposite, pool.itokenDecimals)}
                  />
                  {/* 最大按钮 */}
                  <Button
                    className="pools__dialog__deposit-all"
                    type={2}
                    onClick={onWithdraw.bind(this, pool, index, true, deposite, false)}
                    loading={fetchWithdrawPending[index]}
                    disabled={
                      !deposite || fetchWithdrawPending[index] || (isMoreDepostLimit(deposite, pool.depostLimit))
                    }>
                    {fetchWithdrawPending[index] ? '' : t('v2-ui-13')}
                  </Button>
                </li>
                {/* 提取本金 */}
                <li>
                  <Button
                    className="pools__dialog__option"
                    loading={fetchWithdrawPending[index]}
                    disabled={!Number(String(withdrawAmount[index]).replace(',', '')) || fetchWithdrawPending[index]}
                    onClick={onWithdraw.bind(this, pool, index, false, deposite, false)}>{fetchWithdrawPending[index] ? '' : t("v2-ui-10")}</Button>
                </li>
              </ul>
            </div> : null
          }
        </div> : null
        }
      </div>
    )
  })

  return (
    <>
      <Dialog
        type={dialogCtrl.type}
        opened={dialogCtrl.opened}
        buttonText={dialogCtrl.buttonText}
        cancelButtonText={dialogCtrl.cancelButtonText}
        cancelButtonCallback={dialogCtrl.cancelButtonCallback}
        buttonClickCallback={dialogCtrl.buttonClickCallback}
      >{dialogCtrl.contentText}</Dialog>
      {/* 说明文字 */}
      <SectionTitle />
      {/* 倒计时 */}
      {/* <SectionCountDown endDate="2021-03-19 14:00:00" date={{ year: 2021, month: 3, day: 19, h: 14, m: 0, s: 0 }} /> */}
      {/* 总锁仓量总收益 */}
      {/* <SectionTotal /> */}
      {
        address?<SectionTotal
          address={address}
        />:null
      }
      {/* 少选过滤 */}
      <SectionFilter isPartake={isPartake} filterPartakeHandler={() => setIsPartake(!isPartake)} />
      <div className="pools-main">
        {/* (Boolean(networkId === Number(process.env.NETWORK_ID)) */}
        {(Boolean(networkId === Number(process.env.NETWORK_ID)) || Boolean(networkId === Number(process.env.NETWORK_ID_II))) && poolsMap}
        {!poolsMap.filter(pool => !!pool).length ? <div className="pools__empty">
          <img src={require("../../../assets/img/empty.svg")} />
          <p>{t("v2-ui-15")}</p>
          <Button type={2} onClick={() => setIsPartake(false)}>{t("v2-ui-16")}</Button>
        </div> : null}
      </div>
    </>
  )
})
